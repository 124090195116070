@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';





/* NUEVO */

.slick-arrow {
    color: $secondary-color !important;
}

.slick-next {}

.slick-slider-container {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 0 !important;
    padding-bottom: 1rem !important;
}




/* Componente sponsor interno  */

.img-sponsor-container {
    height: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.img-sponsor {
    max-height: 3.5rem;
    min-width: 3.5rem;
    max-width: 4.5rem;
    width: 3.5rem;
    object-fit: contain;
}

.sponsor {
    cursor: pointer;
    padding: 0.5rem !important;
    /* border: 1px solid rgb(83, 83, 83); */
    height: 9rem;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.slick-arrow {
    fill: $secondary-color !important;

}

.inner-sponsor {
    border-radius: 5rem;
    padding: 1rem;
}

.sponsor-container {
    padding: 0.5rem 0.3rem 0.3rem 0.3rem;
}

.sponsor-selected {
    border-radius: 2rem;
    border: 1px solid $primary-color;
}

/* .sponsor-selected::after{
    content: "selected";
    position: absolute;
    top: 0.5rem;
    background-color: rgba(58, 58, 58, 0.20);
} */
.sponsor h6 {
    padding: 0;
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    font-weight: 400;
    width: 5rem;
    /* height: 2rem; */
}


/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {

    .sponsor-container {
        padding: 1rem 0.5rem 1rem 0.5rem;
    }

    .slick-slider-container {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        padding-top: 0 !important;
        padding-bottom: 2rem !important;
    }

    .img-sponsor-container {
        height: 4rem;
        width: 100%;
        margin-top: 1rem;
    }

    .img-sponsor {
        max-height: 3.5rem;
        min-width: 3.5rem;
        max-width: 6.5rem;
        width: 6.5rem;
    }

    .sponsor {
        padding: 0.5rem !important;
        height: 10rem;
    }

    /* .inner-sponsor{
        border-radius: 5rem;
        padding: 1rem;
    } */

    .sponsor h6 {
        padding: 0;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        font-weight: 400;
        width: 7rem;
        /* height: 2rem; */
    }

}