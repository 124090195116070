@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';

.feedback-dialog {
    .MuiDialogTitle-root {
        background-color: $primary-color;
        color: #ffffff;
        padding: 1rem 1.5rem;
        font-size: 1.25rem;
        font-weight: 600;

        @media (max-width: 600px) {
            font-size: 1.1rem;
            padding: 0.8rem 1rem;
        }
    }

    .MuiDialogContent-root {
        padding: 1rem 1.5rem;
        background-color: #f9f9f9;

        @media (max-width: 600px) {
            padding: 0.8rem 1rem;
        }
    }

    .MuiDialogActions-root {
        padding: 0.5rem 1.5rem;
        background-color: #f1f1f1;
        justify-content: flex-end;

        @media (max-width: 600px) {
            padding: 0.5rem 1rem;
        }
    }

    .form-group {
        margin-bottom: 1.5rem;
    }

    .rating-section {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #e0e0e0;

        .label {
            color: #333;
            font-size: 1rem;
            margin-bottom: 0.5rem;

            @media (max-width: 600px) {
                font-size: 0.95rem;
            }
        }

        .MuiRating-root {
            font-size: 2rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
            }
        }
    }

    .contact-toggle {
        margin-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #e0e0e0;

        .MuiTypography-root {
            font-size: 1rem;

            @media (max-width: 600px) {
                font-size: 0.95rem;
            }
        }

        .toggle-buttons {
            display: flex;
            gap: 0.5rem;
            margin-top: 0.5rem;
        }

        .MuiIconButton-root {
            width: 2.5rem;
            height: 2.5rem;

            @media (max-width: 600px) {
                width: 2rem;
                height: 2rem;
            }

            .MuiSvgIcon-root {
                font-size: 1.25rem;

                @media (max-width: 600px) {
                    font-size: 1rem;
                }
            }
        }
    }

    .character-counter {
        text-align: right;
        font-size: 0.875rem;
        color: #666;

        @media (max-width: 600px) {
            font-size: 0.75rem;
        }

        &.warning {
            color: #f44336;
        }
    }

    .error-message {
        font-size: 0.875rem;
        color: #f44336;
        margin-top: 0.25rem;

        @media (max-width: 600px) {
            font-size: 0.75rem;
        }
    }

    .MuiButton-root {
        border-radius: 0.5rem;
        padding: 0.75rem 1.5rem;
        font-size: 0.9rem;
        font-weight: 500;
        transition: background-color 0.3s ease, transform 0.2s ease;

        @media (max-width: 600px) {
            padding: 0.5rem 1rem;
            font-size: 0.85rem;
        }

        &.cancel-button {
            background-color: transparent;
            color: $primary-color;
            border: 1px solid $primary-color;

            &:hover {
                background-color: rgba(25, 118, 210, 0.08);
            }

            &:active {
                background-color: rgba(25, 118, 210, 0.16);
            }
        }

        &.send-button {
            background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
            color: #ffffff;

            &:hover {
                background: linear-gradient(45deg, #1e88e5 30%, #1ecbf3 90%);
                transform: translateY(-2px);
            }

            &:active {
                transform: translateY(0);
                box-shadow: 0 2px 4px rgba(33, 150, 243, 0.2);
            }

            &:disabled {
                background: #90caf9;
                cursor: not-allowed;
                box-shadow: none;
            }
        }
    }

    .evaluate-counterparts-dialog {
        .MuiDialogTitle-root {
            background-color: $primary-color;
            color: #ffffff;
            padding: 1rem 1.5rem;
            font-size: 1.25rem;
            font-weight: 600;

            @media (max-width: 600px) {
                font-size: 1.1rem;
                padding: 0.8rem 1rem;
            }
        }

        .MuiDialogContent-root {
            padding: 1rem 1.5rem;
            background-color: #f9f9f9;

            @media (max-width: 600px) {
                padding: 0.8rem 1rem;
            }

            .MuiListItem-root {
                padding: 0.5rem 0;

                @media (max-width: 600px) {
                    padding: 0.4rem 0;
                }

                .MuiListItemText-primary {
                    font-size: 1rem;

                    @media (max-width: 600px) {
                        font-size: 0.95rem;
                    }
                }

                .MuiIconButton-root {
                    width: 2rem;
                    height: 2rem;

                    @media (max-width: 600px) {
                        width: 1.75rem;
                        height: 1.75rem;
                    }

                    .MuiSvgIcon-root {
                        font-size: 1.25rem;

                        @media (max-width: 600px) {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        .MuiDialogActions-root {
            padding: 0.5rem 1.5rem;
            background-color: #f1f1f1;
            justify-content: flex-end;

            @media (max-width: 600px) {
                padding: 0.5rem 1rem;
            }
        }

        .MuiButton-root {
            border-radius: 0.5rem;
            padding: 0.75rem 1.5rem;
            font-size: 0.9rem;
            font-weight: 500;
            transition: background-color 0.3s ease, transform 0.2s ease;

            @media (max-width: 600px) {
                padding: 0.5rem 1rem;
                font-size: 0.85rem;
            }

            &.cancel-button {
                background-color: transparent;
                color: $primary-color;
                border: 1px solid $primary-color;

                &:hover {
                    background-color: rgba(25, 118, 210, 0.08);
                }

                &:active {
                    background-color: rgba(25, 118, 210, 0.16);
                }
            }
        }
    }

    .comment-section {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .character-counter {
            text-align: right;
            font-size: 0.875rem;
            color: #666;

            @media (max-width: 600px) {
                font-size: 0.75rem;
            }

            &.warning {
                color: #f44336;
            }
        }
    }

    @media (max-width: 600px) {

        .rating-section .label,
        .contact-toggle .MuiTypography-root {
            font-size: 0.95rem;
        }

        .rating-section,
        .contact-toggle {
            padding: 0.5rem 0;
            margin-bottom: 1rem;
        }

        .form-group {
            margin-bottom: 1rem;
        }

        .error-message {
            font-size: 0.75rem;
        }

        .MuiButton-root {
            width: 100%;
            margin-bottom: 0.5rem;
        }

        .MuiPagination-root {
            margin-top: 1.5rem;
        }

        .contact-toggle {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .contact-toggle .MuiIconButton-root {
            flex: 1;
            margin: 0 0.25rem;
        }
    }
}