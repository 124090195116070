.contact-toggle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.toggle-buttons {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.contact-toggle .MuiIconButton-root.primary {
    background-color: #1e88e5;
    color: #ffffff;
    width: 2.5rem;
    height: 2.5rem;
}

.contact-toggle .MuiIconButton-root.default {
    background-color: #e0e0e0;
    color: #9e9e9e;
    width: 2.5rem;
    height: 2.5rem;
}

.contact-toggle .MuiIconButton-root.primary:hover {
    background-color: #1565c0;
}

.contact-toggle .MuiIconButton-root.default:hover {
    background-color: #bdbdbd;
}

@media (max-width: 600px) {

    .contact-toggle .MuiIconButton-root.primary,
    .contact-toggle .MuiIconButton-root.default {
        width: 2rem;
        height: 2rem;
    }

    .contact-toggle .MuiIconButton-root.primary .MuiSvgIcon-root,
    .contact-toggle .MuiIconButton-root.default .MuiSvgIcon-root {
        font-size: 1rem;
    }

    .contact-toggle {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .toggle-buttons {
        margin-top: 0;
    }
}