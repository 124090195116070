/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.navbar-item-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    gap: 0rem 0rem;
}
.navbar-item-container::after{
    content: " ";
    height: 2rem;
    right: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.151);
}
.navbar-item-container::before{
    content: " ";
    height: 2rem;
    left: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.151);
}

@media (max-width: 767px) {
    .navbar-item-container{
        display: none;
    }
}

/*   Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .navbar-item-container{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
