.fondo-imagen {
    background-image: url('../../../public/media/Buscador/BannerBuscador.png'); // Asegúrate de usar la ruta correcta a tu imagen
    background-size: cover; // Cubre completamente el contenedor
    background-position: center; // Centra la imagen en el contenedor
    height: auto; // Ajusta la altura según necesite
    position:relative;
    @media (max-width: 1200px){
        background-image: url('../../../public/media/Buscador/BannerColor.png');
        height: auto;
    }
}
.imagen-redonda {
    border-radius: 50%; // Hace que la imagen sea completamente redonda
    overflow: hidden; // Asegura que la imagen no se desborde del borde redondo
    width: 60%; // Ajusta el ancho según necesites
    height: auto; // Ajusta la altura para que sea igual al ancho y mantener la forma redonda
    object-fit: cover; // Asegura que la imagen cubra completamente el contenedor sin distorsionarse
    position:absolute;

}
.text-container{
    margin-top: 5%;
    color:white;
    font-family: 'Space Grotesk';
    margin-left: 4rem;
    .title{
        font-size: 3rem;
    }
    .subtitle{
        font-size: 1.3rem;
        margin-right: 3rem;
    }
    @media (max-width: 1200px) {
        margin-left: 1rem;
        
    }
}
.btn-container{
    margin-left: 3rem;
    @media (max-width: 1200px) {
        margin-left: 0rem;
    }
}