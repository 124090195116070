@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';


.alerts-container{
    position: fixed;
    top: $navbar-height;
    margin-top: 1rem;
    margin-right: 2rem;
    right: 0;
    width: 22rem;
    z-index: 9999 !important;
}

.alerts-title{
    font-size: 1rem !important;
    font-weight: 700 !important;
}

.alerts-desc{
    font-size: 0.8rem;
    font-weight: 400;
}

.loading-alert{
    background-color: $navbar-bg-color !important;
    color: white !important;
    z-index: 9999 !important;
}
.loading-alert-icon{
    color: white !important;
}