@import '../../../../styles/colors.scss';
@import '../../../../styles/buttons.scss';
@import '../../../../styles/spacings.scss';
@import '../../../../styles/sizes.scss';

.userbutton-container {
    cursor: pointer;
    /* position: relative; */
    height: $navbar-height;
    /* padding-right: 1rem; */
}
.userbutton-container:hover,
.userbutton-container.active{
    background-color: rgba(128, 128, 128, 0.281);
}

.userbutton-img {
    background-color: lightgray;
    border-radius: 100rem;
    width: 3.5rem;
    height: 3.5rem;
}

.userbutton-sidebar {
    z-index: 0;
    display: none;
    background-color: white;
}

.userbutton-sidebar.active {
    z-index: 999;
    opacity: 1;
    display: block;
}


.userbutton-sidebar-link{
    padding: 1rem $general-spacing 1rem $general-spacing;
    text-decoration: none !important;
    cursor: pointer;
}
.userbutton-sidebar-link:hover{
}




.userbutton-sidebar-fondo{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    display: none;
}

.userbutton-sidebar-fondo.active{
    display: block;
}


@media (max-width: 767px) {
    .userbutton-container {
        /* position: relative; */
    }
    .userbutton-sidebar {
        padding: 0 0 0 0;
        display: none;
        position: absolute;
        top: $navbar-height;
        top: 0;
        right: 0;
        width: 75vw;
        min-height: 100vh;
    
        opacity: 0;
        transition: opacity 1s ease;
        box-shadow: 4px 4px 4px 0px rgba(0,0,0,0.1);
        -webkit-box-shadow: 4px 4px 4px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 4px 4px 4px 0px rgba(0,0,0,0.1);
    }
}

/*   Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .userbutton-container {
        position: relative;
    }
    .userbutton-sidebar {
        border-radius: 0.3rem;
        background-color: $bg-color-3;
        background-color: white;
        position: absolute;
        top: calc($navbar-height) + $general-spacing-x05;
        right: 0;
        opacity: 0;
        transition: opacity 0.5s ease;
        width: 20rem;
        box-shadow: 4px 4px 4px 0px rgba(0,0,0,0.1);
        -webkit-box-shadow: 4px 4px 4px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 4px 4px 4px 0px rgba(0,0,0,0.1);
    }
}

.userbutton-sidebar-user{
    background-color: $bg-color-3
}


.ancho{
    width: 20rem !important;
}

/*  */


.userbutton-progreso{
    width: 100%;
    padding: 0 $general-spacing-x2 0 $general-spacing-x2;
}

.userbutton-progreso-top{
    display: flex;
    justify-content: space-between;
}
.userbutton-progreso-top-frac{
    color: $primary-color;
}
.userbutton-progreso-top-label{

}
.userbutton-progreso-bar{
    
}
.userbutton-progreso-bar .progress-bar{
    background-color: $primary-color;
}
