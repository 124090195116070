@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';


.card{
    border-radius: 0.5rem;    
    padding: 1rem;
    border: 1px solid $card-border-color;
    background-color: $card-bg-color;
    color: $card-text-color;
    -webkit-box-shadow: 10px 10px 5px -5px rgba(100,100,100,0.1);
    -moz-box-shadow: 10px 10px 5px -5px rgba(100,100,100,0.1);
    box-shadow: 10px 10px 5px -5px rgba(100,100,100,0.1);
    z-index: 2 !important;
}

/* .card:hover{
    border: 1px solid blue;
} */
