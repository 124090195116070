.construction-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f2f2f2;
    
    h1 {
      font-size: 32px;
      margin-bottom: 16px;
    }
    
    p {
      font-size: 18px;
      text-align: center;
    }
    .construction-page-icon{
      height: 6rem;
      width: 6rem;
      margin-bottom: 2rem;
    }
  }