/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import '../../../../styles/colors.scss';
@import '../../../../styles/buttons.scss';
@import '../../../../styles/spacings.scss';
@import '../../../../styles/sizes.scss';

.notificacion{
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.titulo-notificaciones{
    border-bottom: 1px solid black;
    height: $navbar-height;
    background-color: #6B6B6B;
    color: white;

}

.notificaciones-container{
    overflow-y: auto;
    height: 100% !important;
    //height: calc(100vh - $navbar-height);
}

.notificacion-vista{
    background-color: rgb(226, 226, 226);
}
.notificacion-texto-titulo{
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    color: #8a8a8a !important;
    margin-bottom: 0 !important;
}
.notificacion-texto-desc{
    font-size: 0.9rem;
    margin-bottom: 0 !important;
    color: #555555 !important;
}
.notificacion-texto-time{
    font-size: 0.75rem;
    margin-bottom: 0 !important;
    color: #5a5a5a !important;
}
.diferencia-tiempo-notificacion{
    font-size: 0.8rem;
}

#not-name-user{
    font-size: 1.2rem;
    font-weight: bold;
}

#not-message{
    font-size: 0.8rem;
}

#not-time{
    font-size: 0.6rem;
}

.profile-picture {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
  
  
.card-msj{
    cursor: pointer !important;
    width: 100% !important;
    border-radius: 0 !important;
}
.card-msj-visto{
    background-color: #F2F2F2 !important;
}
.card-msj-visto:hover{
    background-color: #E5E5E5 !important;
}

.card-msj-no-visto{
    background-color: #C7EFF9 !important;
}
.card-msj-no-visto:hover{
    background-color: #bfdbe2 !important;
}


  



.color-0 {
    background-color: rgb(211, 255, 255) !important;
    background-color: #d8ecff !important;
    
}

.color-1 {
    background-color: rgb(255, 255, 255) !important;
}

.color-0:hover{
    background-color: rgb(227, 242, 243) !important;
}

.color-1:hover{
    background-color: rgb(231, 233, 238) !important;
}