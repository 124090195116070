@import './colors.scss';

/* ==========================================        BOTON GENERAL    ========================================================= */
.btn {
    border-radius: 90rem;
    border-radius: 2px;
    padding: 0.5rem 2rem 0.5rem 2rem;
}

.btn-sm {
    border-radius: 90rem;
    border-radius: 5px;
    padding: 0.3rem 0.8rem 0.3rem 0.8rem;
}
.btn-lg{
    font-size: 1.3rem;
    border-radius: 90rem;
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;

}

/* ==========================================        BOTON PRIMARY    ========================================================= */
.btn-primary {
    color: $white-color !important;
    background-color: $primary-color !important;
    font-weight: 800;

    &:hover,
    &:active {
        &:not([disabled]) {
            color: $primary-color !important;
            background-color: $white-color !important;
            border-width: 1px;
            border-color: $primary-color;

        }
    }

    &:disabled {
        background-color: rgba(107, 107, 107, 0.295) !important;
        border-width: 0px;
        border-color: $primary-color !important;
    }
}

.btn-outline-primary {
    border-color: $primary-color !important;
    color: $primary-color !important;
    border-width: 1px;
    font-weight: 600;

    &:hover,
    &:active {
        &:not([disabled]) {
            background-color: $primary-color-hover !important;
            color: white !important;
        }
    }
}

/* ==========================================        BOTON SUCCESS    ========================================================= */
.btn-success {
    background-color: $success-color !important;
    font-weight: 600;

    &:hover,
    &:active {
        &:not([disabled]) {
            background-color: $success-color-hover !important;
        }
    }

}

.btn-outline-success {
    color: $success-color !important;
    font-weight: 600;
    border-color: $success-color !important;
    border-width: 1px;

    &:hover,
    &:active {
        &:not([disabled]) {
            background-color: $success-color-hover !important;
            color: white !important;
        }
    }
}

/* ==========================================        BOTON WHITE    ========================================================= */
.btn-white {
    background-color: $white-color !important;
    font-weight: 600;

    border-color: $secondary-color !important;
    border-width: 1px;

    &:hover,
    &:active {
        &:not([disabled]) {
            background-color: $white-color-hover !important;
            color: white !important;
        }
    }
}

.btn-outline-white {
    border-color: $white-color !important;
    color: $white-color !important;
    border-width: 1px;
    font-weight: 600;

    &:hover,
    &:active {
        &:not([disabled]) {
            background-color: $white-color-hover !important;
            color: white !important;
        }
    }
}



/* ==========================================        BOTON DANGER    ========================================================= */
.btn-danger {
    background-color: $danger-color !important;
    font-weight: 600;


    &:hover,
    &:active {
        &:not([disabled]) {
            color: $danger-color-hover !important;
            background-color: $white-color !important;
            border-width: 1px;
            border-color: $danger-color-hover;
        }
    }
}

.btn-outline-danger {
    border-color: $danger-color !important;
    color: $danger-color !important;
    border-width: 1px;
    font-weight: 600;

    &:hover,
    &:active {
        &:not([disabled]) {
            background-color: $danger-color-hover !important;
            color: white !important;
        }
    }
}


/* ==========================================        BOTON Dark    ========================================================= */
.btn-dark {
    background-color: $secondary-color !important;
    font-weight: 600;

    border-color: $secondary-color !important;
    border-width: 1px;

    &:hover,
    &:active {
        &:not([disabled]) {
            background-color: $secondary-color-hover !important;
            color: white !important;
        }
    }
}

.btn-outline-dark {
    border-color: $secondary-color !important;
    color: $secondary-color !important;
    border-width: 1px;
    font-weight: 600;

    &:hover,
    &:active {
        &:not([disabled]) {
            background-color: $secondary-color-hover !important;
            color: white !important;
        }
    }
}



/* ==========================================        BOTON SUCCESS    ========================================================= */




/* Small devices (tablets, 767px and own) */
@media (max-width: 767px) {}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up)  */
@media (min-width: 992px) {}

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}