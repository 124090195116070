@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';

.signIn{
    background-color: $login-bg-color;
    height: calc(100vh - $navbar-height);
    width: 100vw;
    margin-top: $navbar-height;
}


.signIn-container{
    background-color: $body-bg-color;
    height: 100vh;
    height: max(calc(100vh - $navbar-height), 500px);
}

.signIn-content-container{
    background-color: $card-bg-color;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.signIn-sidebar-background-container{
    background-color: $body-bg-color;
    position: relative;
}

.signIn-sidebar-background-container::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: 130vh 130vh;
    transform: rotate(0deg);
    background-image: url("../../../../public/media/fondos/fondo1.png");
    background-position: 0% 0%;
    background-repeat: no-repeat;
}
