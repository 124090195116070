@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';

.titulo-clase-item{
    color: gray;
}
.Accordion{
    margin: 0 !important;
}
.detalles{
    padding: 0 !important;
}
.settings-sidebar-card{    
    width: 100% !important;
    padding: 2rem 0rem 0rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    
}

@media (max-width: 767px) {
    .settings-sidebar{
        display: none;
    }
}
/*   Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .settings-sidebar{
        display: block;
    }
}

/*  */

.settings-sidebar-progreso{
    width: 100%;
    margin-top: $general-spacing;
    padding: 0 $general-spacing 0 $general-spacing;
}

.settings-sidebar-progreso-top{
    display: flex;
    justify-content: space-between;
}
.settings-sidebar-progreso-top-frac{
    color: $primary-color;
}
.settings-sidebar-progreso-top-label{

}
.settings-sidebar-progreso-bar{
    
}
.settings-sidebar-progreso-bar .progress-bar{
    background-color: $primary-color;
}

.settings-sidebar-item-incomplete{
    color: $primary-color;
}


.settingsSidebar-img {
    background-color: lightgray;
    border-radius: 100rem;
    width: 5rem;
    height: 5rem;
}

.MuiListItem-button.active{
    color: $primary-color !important;
}

/* Manual usuario  NUEVO*/
.manual-usuario-link {
    color:  #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
  
 
