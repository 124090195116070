.participant {
    margin: 0;
    box-sizing: border-box;
    position: relative;

    border-radius: 1rem;

    width: 100%;
    height: 100% !important;
    
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: rgb(66, 66, 66);
    border: 1px solid black
}
.participant-fullscreen-remote{
    margin: 0;
    box-sizing: border-box;
    position: relative;

    border-radius: 1rem;

    /* width: 100vw;
    height: 100vh !important;
    max-height: 100vh !important; */
    
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: rgb(66, 66, 66);

}
.participant-fullscreen-local{
    margin: 0;
    box-sizing: border-box;
    border-radius: 1rem;

    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: rgb(66, 66, 66);

    position: absolute;
    bottom: 4rem !important;
    right: 1rem !important;

    width: 25vh !important;
    height: 16vh !important;
    border: 2px solid rgba($color: #030303, $alpha: 0.6);
}
.participant-fullscreen-local .participant-footer{
    
    height: 2rem;
    background-color: rgba($color: #000000, $alpha: 0.0);
}
.participant-fullscreen-local .name{
    display: none;
}
.participant-fullscreen-local .devices{
    font-size: 0.8rem;
}

.participant-fullscreen-local .participant-foto{
    width: 7rem;
    height: 7rem;
    border-radius: 10rem;
}

.participant-video {
    /* min-height: calc(4rem*3) !important;
    min-height: min(calc(11.34rem*2.5), 50vh) !important;
    min-width: min(calc(11.34rem*2.5), 85vw) !important;
    height: 12rem !important;
    background-color: rgb(82, 82, 82); */
    height: 100%;
}

.participant-audio {
    background-color: rgb(172, 154, 54);
}

.participant-video.no-video {
    width: 100% !important;
    height: 100% !important;
    background-color: rgb(172, 154, 54);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.participant-foto-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.participant-foto {
    width: 10rem;
    height: 10rem;
    border-radius: 10rem;
}

/* .identity {
    position: absolute;
    left: 0px;
    bottom: 0px !important;
    color: white;
    padding-left: 15px;
  } */
.participant-footer{
    position: absolute;
    left: 0px;
    bottom: 0px !important;
    color: white;
    padding-left: 15px;
    background-color: rgba(7, 7, 7, 0.2);
    width: 100%;
    padding: 1rem;
    margin: 0 !important;
    height: 3rem;
    display: flex;
    align-items: center;
}