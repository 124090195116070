/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';

.navbarItem-foto{
    height: 1.7rem !important;
    width: 1.7rem !important;
}

.navbarItem{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 4rem;
    height: $navbar-height;
    cursor: pointer;
    padding-top: 0.24rem;
    padding-bottom: 0.24rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    color: $navbar-text-color;
    text-decoration: none !important;
    position: relative; /*  */
}
.navbarItem:hover{
    background-color: $navbar-bg-color-hover;
    color: $primary-color;
}
.navbarItem.active{
    background-color: $navbar-bg-color-active;
    border-bottom: 0.24rem solid $primary-color;
    padding-top: 0.24rem;
    padding-bottom: 0;
    color: $navbar-text-color-active;
}

.navbarItem-icon-container{
    margin: 0;

}

.navbarItem-icon{

}

.navbarItem-label{
    margin: 0;
    font-size: 0.70rem;
    padding-left: 0.6rem;
    letter-spacing: 1px;

}


.navbarItem-counter {
    /* circle shape, size and position */
    position: absolute;
    right: 0.9rem;
    top: 0.4em;
    min-width: 1.6em;
    /* or width, explained below. */
    padding: 0;
    border-radius: 10rem;
    border: 0px solid white;
    background-color: red;
    background-color: $primary-color;

    /* number size and position */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: white;
}
.navbarItem-counter-text{
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
}