@import '../../../../styles/colors.scss';
@import '../../../../styles/buttons.scss';
@import '../../../../styles/spacings.scss';
@import '../../../../styles/sizes.scss';

.notificacion{
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.titulo-notificaciones{
    border-bottom: 1px solid black;
    height: $navbar-height;
}

.notificaciones-container{
    height: 100%;
    height: calc(100vh - $navbar-height);
    overflow-y: auto;
}

.notificacion-vista{
    background-color: rgb(226, 226, 226);
}
.notificacion-texto{
    font-size: 0.9rem;
    color: blue;
}
.diferencia-tiempo-notificacion{
    font-size: 0.8rem;
}

.dropicon{
    color: white;
    margin-left: 5px;
}

.language-menu-container .MuiButtonBase-root{
    padding: 0 !important;
    min-width: none !important;
}