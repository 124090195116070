@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';

.body-settings {
    padding-top: calc($navbar-height*0.5)!important;
    padding-bottom: calc($navbar-height*2)!important;
}

.settings-body-container-inner {}

.settings-sidebar {
    position: fixed;
    top: $navbar-height;
    padding: 1rem 0 1rem 0;
    padding-top: calc($navbar-height*0.5)!important;
    overflow-y: overlay !important;
    overflow-x: unset !important;
    height: calc(100vh - $navbar-height);
    width: 20rem;
}
 



/* Small devices (tablets, 767px and own) */
@media (max-width: 992px) {
    .body-settings {
        margin-left: 0;
    }

    .settings-sidebar {
        display: none;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 993px) {
    .settings-sidebar {
        display: block;
    }
}

/* Large devices (desktops, 992px and up)  */
@media (min-width: 992px) {

    .body-settings {
        margin-left: 20rem;
    }
}

