
/* colores: fondos */
$bg-color-1: rgb(230, 230, 230);
$bg-color-1-hover: rgb(199, 199, 199);
$bg-color-2: #e1e1e1;
$bg-color-2-hover: #c6c6c6;
$bg-color-2-active: #9e9ccb;
$bg-color-3: #3D3D3B;
$bg-color-3-hover: #333333;
$bg-color-3-active: #212121;
$bg-color-4: rgb(245, 245, 255);
$bg-color-4-hover: #4f4f4f;

/* colores */

$primary-color: #009EE3;
$primary-color-hover: #1898cf;
$secondary-color-light: #6b6b6b;
$secondary-color: $bg-color-3;
$secondary-color-hover: $bg-color-3-hover;
$secondary-color-active: $bg-color-3-active;
$success-color: rgb(76, 192, 76);
$success-color-hover: rgb(76, 192, 76);
$warning-color: yellow;
$danger-color: #E20E18;
$danger-color-hover: #ee2731;
$danger-color-active: #bb131b;
$info-color: lightblue;
$white-color: white;
$white-color-hover: rgb(231, 231, 231);



/* COLORES TEXTOS */
$text-color-1: black;
$text-color-2: rgb(1, 1, 1);
$text-color-3: white;
$text-color-3-hover: rgb(165, 165, 165);
$text-color-3-active: white;

.text-primary {
    color: $primary-color !important;
}
.text-secondary {
    color: $secondary-color !important;
}
.text-success {
    color: $success-color !important;
}
.text-danger {
    color: $danger-color !important;
}
.text-warning {
    color: $warning-color !important;
}
.text-info {
    color: $info-color !important;
}


/* COLORES COMPONENTES */
$body-bg-color: rgb(240, 240, 250);
$bottomnav-bg-color: $bg-color-3;
$bottomnav-bg-color-hover: $bg-color-3-hover;
$bottomnav-bg-color-active: $bg-color-3-active;

$navbar-bg-color: $bg-color-3;
$navbar-bg-color-hover: $bg-color-3-hover;
$navbar-bg-color-active: $bg-color-3-active;
$footer-bg-color: $bg-color-3;
$login-bg-color: rgb(201, 201, 201);
$login-bg-color-2: rgb(218, 218, 218); 

$navbar-text-color: $text-color-3;
$navbar-text-hover: $text-color-3-hover;
$navbar-text-color-active: $text-color-3-active;
$footer-text-color: rgb(0, 0, 0);
$bottomnav-text-color: $text-color-3;
$bottomnav-text-color-hover: $text-color-3-hover;
$body-text-color: $text-color-1;
/*  */


/* card */
$card-bg-color: white;
$card-border-color: rgb(196, 196, 196);
$card-border-color-active: $primary-color;
$card-text-color: rgb(139, 137, 137);
$card-text-color-active: rgb(64, 64, 64);

// src/styles/colors.scss

$card-background-color: #ffffff; // Blanco
$card-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
$card-hover-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
$text-color: #333333; // Gris oscuro para el texto
$inscribirse-button-color: #009EE3; // Azul claro para botones
$inscribirse-button-hover-color: darken($inscribirse-button-color, 10%);
$header-text-color: #1976d2; // Azul primario para headers


// src/styles/colors.scss

$card-background-color: #ffffff; // Blanco
$card-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
$card-hover-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
$text-color: #333333; // Gris oscuro para el texto
$inscribirse-button-color: #009EE3; // Azul claro para botones
$inscribirse-button-hover-color: darken($inscribirse-button-color, 10%);
$header-text-color: #1976d2; // Azul primario para headers

