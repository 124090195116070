@import 'styles/colors.scss';
@import 'styles/buttons.scss';
@import 'styles/spacings.scss';
@import 'styles/sizes.scss';

/* html, body, main{
  margin: 0 !important;
  padding: 0 !important;
   height: 100%;
  height: -webkit-fill-available; 
  overflow: hidden !important;
  height: 100svh !important;
} */

main{
  margin: 0 !important;
  padding: 0 !important;
  /* height: 100%;
  height: -webkit-fill-available;  */
  width: 100vw;
  overflow: hidden !important;
  height: 100vh !important;
  height: 100svh !important;
}
/* main{
  height: -webkit-fill-available; 
  height: 100svh;

  
} */

.body-container{
  padding-top: $navbar-height;
  //width: 100vw !important;
  //min-height: 100% !important;
  
  //scrollbar-gutter: stable both-edges;

  height: 100%;
  width: 100vw;
  overflow-y: scroll;
  overflow-y: overlay !important;
  overflow-x: hidden; 
  /* 
  */
  
}

/* COLORES DE PRUEBA */
/* html{
  background-color: blue !important;
}
body{
  background-color: red !important;
}

main{
  background-color: green !important;
}
.body-container{
  background-color: violet !important;
}

.body-container *{
  background-color: rgba(122, 122, 122, 0.192) !important;
  
}
 */



.mb-0{
  margin-bottom: 0 !important;
}
.cursor-pointer{
  cursor: pointer;
}
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* --------------------------------------------- CLASES TEXTOS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.parrafo1 {
  font-size: 1rem !important;
}
.parrafo2 {
  font-size: 0.9rem !important;
}
:root {
  font-size: 14px;
  font-family: 'Lato', sans-serif !important;
  font-family: 'Lato' !important;
}

.prevent-select {
  -webkit-user-select: none !important;
  /* Safari */
  -ms-user-select: none !important;
  /* IE 10 and IE 11 */
  user-select: none !important;
  /* Standard syntax */
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
b {
  margin: 0;
}



/* Small devices (tablets, 767px and own) */
@media (max-width: 767px) {
  :root {
    font-size: 14px !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 991px) {
  :root {
    font-size: 15px !important;
  }
}

/* Large devices (desktops, 992px and up)  */
@media (max-width: 1200px) {
  :root {
    font-size: 15px !important;
  }

}

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  :root {
    font-size: 15px !important;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  :root {
    font-size: 18px !important;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 2000px) {
  :root {
    font-size: 20px !important;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 3000px) {
  :root {
    font-size: 40px !important;
  }
}

.btn-rounded{
  border-radius: 10rem;
}


