@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';


.signup-layout {
    height: 100%;
    /* padding-top: 2rem !important;  */
    padding-top: calc($navbar-height + 2rem) !important;
    padding-bottom: 2rem !important;
    background-color: $body-bg-color;

    /* display: flex;
    justify-content: center;
    align-items: center; */
    background-image: url("../../../../public/media/fondos/fondo2.png");

}

.signup-container {
    /* width: 100% !important;
    height: auto !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; */
}



/* Small devices (tablets, 767px and own) */
@media (max-width: 992px) {
    .signup-layout {
        padding-top: $navbar-height !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
    }

    /* 
    .signup-container{
        border-radius: 0 !important;
        border: none !important;
        width: 100% !important;
        height: 100% !important;
        padding-top: $navbar-height !important;
        padding-bottom: $navbar-height !important;
    } */
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 992px) {
    .signup-layout {
        /* overflow-y: overlay; */
    }

    .signup-container {}
}