@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';
.rmdp {
    font-size: 20px !important; /* Tamaño de las letras */
  }

.rmdp .rmdp-day {
font-size: 20px !important; /* Tamaño de las letras para los días */
}

.rmdp .rmdp-button {
font-size: 20px !important; 
width: 40px !important; 
height: 40px !important; 
}
.calendario-container *{
    /* font-size: 19px !important; */
}
.calendario2{
    /* background-color: red !important;
    padding: 2rem !important;
    height: 30rem;
    width: 30rem;
     */
    font-size: 19px !important;
}
.rmdp-day{
    /* width: 2rem !important;
    background-color: red !important; */
}

.rmdp-day span{
    /* font-size: 1.2rem !important; */
}

.Calendar .rmdp-day .sd{
    font-size: 2rem !important;
}

.dias-seleccionados-container{
    /* max-height: 300px;
    overflow-y: auto; */
}
.dia-seleccionado {
    background-color: $primary-color;
    color: white;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
}
.dia-seleccionado-date{
    font-size: 1rem;
    font-weight: 400;
}

.close-button{
    padding: 0.5rem;
    border-radius: 10rem;
    height: 2rem;
    width: 2rem;
    background-color: $primary-color;
    background-color: white;
    border-color: white;
    color: white;
    color:  $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}
.close-button{
    padding: 0;
    margin: 0;
}

.Calendar, 
.Calendar > div.react-datepicker-wrapper, 
.Calendar > div > div.react-datepicker__input-container
.Calendar > div > div.react-datepicker__input-container input {
   width: 100%;
}