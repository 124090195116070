
.img-compania-option{
  height: 2rem;
  width: 2rem;
  display: inline-block;
  background-color: red;
}


.css-x15wq9 {
  position: absolute !important; /* Cambiar de fixed a absolute  esto para el scroll bar, falta testing*/
  z-index: 100 !important;  
}


/* Small devices (tablets, 767px and own) */
@media (max-width: 767px) {
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up)  */
@media (min-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
  
  
  