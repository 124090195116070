@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';

.startup-name {
    font-size: 1.4rem;
}

.card-startup {
    cursor: default !important;
    color: $secondary-color;
    border-radius: 1rem;
    padding: 1.4rem;
    position: relative;
    border: 1px solid $secondary-color-light;
}

.card-startup-icono-boton-redes-sociales {
    height: 1.8rem;
    width: 1.8rem;
    margin-right: 0.4rem;
}

.avatar-startup {
    height: 9rem !important;
    width: 9rem !important;
    font-size: 3rem;
}

.card-startup-number {
    position: absolute !important;
    left: -1.25rem !important;
    top: 1rem !important;
    background-color: white !important;
    border: 1px solid $secondary-color-light !important;
    color: $secondary-color-light !important;
    font-weight: 700 !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 3rem !important;
}

.cardStartup-tagInteres {
    background-color: #E0E0E0;
    color: $secondary-color;
    border: 1px solid #c3c3c3;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    font-size: 0.85rem;
    border-radius: 0.5rem;
}