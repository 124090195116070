.card-bloque{
    cursor: pointer;
}
.card-bloque-seleccionado{
    background-color: rgba(152, 152, 211, 0.413);
}


.lista_bloques_tiempo_container{
    max-height: 70vh;
    overflow: auto;
    /* padding: 1rem; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* padding-right: 1rem; */
}

.actual_date_donar_tiempo{
    color: rgb(100, 100, 100);
    /* font-size: 1.2rem; */
}

.label-normal {
    font-weight: normal;
  }

.label-normal-2 {
    font-weight: normal;
    font-style: italic;
    font-palette: rgb(0, 89, 255);;
  }

 