/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';

/* =======================================================================================================================================*/
/* ================= SIDEBAR SMALL =======================================================================================================*/
/* =======================================================================================================================================*/

.bottomnav{
  background-color: white !important;
}
/* 
.bottomnav {
  width: 100vw !important;
  height: $navbar-height !important;
  position: fixed !important;
  bottom: 0;
  left: 0;
  background-color: $bottomnav-bg-color;
  z-index: 999;
} */

.bottomnav-container {
  width: 100vw !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 0;  
}

.bottomnav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
  background-color: $bottomnav-bg-color;
  height: 6rem !important;
  margin: 0;
  color: $bottomnav-text-color;
}

.bottomnav-item.active {
  border-top: 0.3rem solid $primary-color;
  color: $bottomnav-text-color;
  background-color: $bottomnav-bg-color-hover;
}

.bottomnav-item-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 0.5rem;
  margin: 0;
  height: 100% !important;
}


/*   Small devices (tablets, 768px and up)*/
@media (max-width: 767px) {
  .bottomnav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

/*   Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .bottomnav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

  }
}
/*   Large devices (tablets, 992px and up)*/
@media (min-width: 768px) {
  .bottomnav {
    display: none;

  }
}