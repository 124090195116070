.twillio{
  background-color: #000000;
  padding-bottom: 4rem !important;
  border: none !important;
 /*  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden; */
  width: 100%;
  height: 100%;
}
.room-time-warning{
  position: absolute;
  color: white;
  padding: 1rem;
  z-index: 9999;
  top: 5rem;
  left: 0 !important;
  right: 0 !important;
}
.room-time-warning-content{
  height: 2.5rem;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}