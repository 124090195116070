@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';

.generic-card {
  background-color: $card-background-color;
  box-shadow: $card-shadow;
  border-radius: 12px;
  transition: box-shadow 0.3s, transform 0.3s;
  padding: $spacing-md;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    box-shadow: $card-hover-shadow;
    transform: translateY(-4px);
  }

  &__header {
    font-size: $font-size-lg;
    font-weight: 600;
    color: $header-text-color;
    margin-bottom: $spacing-md;
    text-align: center;

    @media (max-width: 600px) {
      font-size: $font-size-md;
    }
  }

  &__content {
    flex: 1;
    overflow-wrap: break-word;
  }

  &__text {
    margin-bottom: 0.5rem;
    font-size: $font-size-base;
    color: $text-color;
    font-weight: 400;
    word-wrap: break-word;
    overflow-wrap: break-word;

    strong {
      font-weight: 600;
      margin-right: 0.5rem;
    }

    @media (max-width: 600px) {
      font-size: $font-size-sm;
    }
  }

  &__action-button {
    min-width: 100px;
    color: white;
    margin: $spacing-sm 0;

    &.MuiButton-containedPrimary {
      background-color: $primary-color;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    &.MuiButton-containedSecondary {
      background-color: $secondary-color;

      &:hover {
        background-color: darken($secondary-color, 10%);
      }
    }

  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: $spacing-sm 0;
  }

  &--dialog {
    max-width: 600px;
    margin: 0 auto;
  }

  @media (max-width: 600px) {}
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.generic-card__text a {
  color: #1976d2;
  /* Color de enlace similar al primary de MUI */
  text-decoration: none;
}

.generic-card__text a:hover {
  text-decoration: underline;
}

.generic-card__list {
  list-style-type: disc;
  padding-left: 20px;
}