@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';

.buscador-texto-container{
    display: block;
    justify-content: start;
    align-items: center;
    background-color: white;
    width: 80%;
    margin-left:4rem;
    border-radius: 10px;
    border: 1px solid #ffffff;
    padding: 5px;
    .input-style{
        border:none;
    }
    .input-style:focus{
        outline: none;
    }
    @media (max-width: 1200px) {
        margin-left: 1rem;
        width: 95%;
    }
}

.boton-tipo-busqueda{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    border-radius: 15px;
    background-color: #81CEE8;
    color: #ffffff;
    border: 2px solid #009EE3;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
        width: 100%;
    }
}
.btn-aplicar-filtro{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 90%;
    height: 80%;
    border-radius: 15px;
    background-color: #81CEE8;
    color: #ffffff;
    border: 2px solid #009EE3;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    margin: auto;
    @media (max-width: 768px) {
        width: 100%;
    }
}
.btn-limpiar-filtro{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 90%;
    height: 80%;
    margin: auto;
    border-radius: 15px;
    background-color: #ffffff;
    color: #009EE3;
    border: 2px solid #009EE3;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
        width: 100%;
    }
}
.boton-tipo-busqueda:hover{

}
.boton-inactivo{
    background-color: #ffffff;
    color: #009EE3;
}
.btn-experto{
    margin-right: 3rem;
}
.home-loading-icon {
    color: $primary-color !important;
    fill: $primary-color !important;
}

.home-content-row {
    background-color: #6b6b6b;
}

.home-content-col {
    background-color: white;
}

.desc-home {
    line-height: 1.3rem;
}

.barra {
    /* position: relative; */
    margin-bottom: 1rem;
    margin-top: 1rem;
    background-color: rgba(0, 0, 0, 0.3);
    background-image: url("../../../public/fondo_barra.png");
    background-size: cover;
}

.home-sin-resultados {
    height: 5rem;
}

.barra .text-center {
    margin: 10px;
}

.barra button {
    margin: 2px !important;
}

.sponsor_list button {
    font-size: 0.5rem !important;
}

.clean_filters button {
    color: #1f1f1f;
    border: 1px solid #3D3D3B;
    font-size: 0.5rem !important;
}

.results_zone {
    margin-top: 3rem;
}


.columna-filtros {
    background-color: $secondary-color-light;
    color: white;
    height: 100% !important;
    min-height: 100vh !important;
}

.filtros-sponsors .MuiChip-root {
    background-color: $secondary-color !important;
    color: white !important;
}

.filtros-industrias .MuiChip-root {
    background-color: $secondary-color-light !important;
    color: white !important;
}

.MuiChip-deleteIconColorDefault {
    color: white;
    fill: white !important;
}

.MuiChip-deleteIconColorDefault :hover {
    color: white;
    fill: rgb(68, 68, 68) !important;
}

.MuiSvgIcon-root.MuiSvgIcon-colorSecondary.MuiSvgIcon-fontSizeMedium.css-gr2mdp-MuiSvgIcon-root {
    fill: rgb(68, 68, 68) !important;

}

.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input {
    margin-top: 3px !important;
}

.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input:active,
.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input:focus {
    outline: 0px solid green !important;
}


.MuiAutocomplete-root.Mui-focused.MuiAutocomplete-hasClearIcon {
    outline: 0px solid green !important;
}

/* FILTROS */
.select-interes-container-container {}

.select-interes-container {}

/* .select-interes{
    background-color: white !important;
} */
.select-interes-container .MuiInputBase-root {
    background-color: white !important;

}

.label-boton-tipo-busqueda {
    font-size: 1rem !important;
}

.home-top-title-container {
    padding-top: 2.3rem !important;
    padding-bottom: 2.5rem !important;
    background-color: $primary-color;
    color: white;
    padding: 2rem !important;
}


/* Small devices (tablets, 767px and own) */
@media (max-width: 767px) {

    .home-top-title-container {
        margin-top: 0 !important;
        padding-top: 1.5rem !important;
        padding-bottom: 2rem !important;
        padding-left: $layout-x-padding-sm !important;
        padding-right: $layout-x-padding-sm !important;
        margin-right: 0 !important;
        border-radius: 0;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 991px) {

    .home-top-title-container {
        margin-top: 0 !important;
        padding-top: 1.5rem !important;
        padding-bottom: 2rem !important;
        padding-left: $layout-x-padding-md !important;
        padding-right: $layout-x-padding-md !important;
        margin-right: 0 !important;
        border-radius: 0;
    }
}

/* Large devices (desktops, 992px and up)  */
@media (min-width: 992px) {
    .label-boton-tipo-busqueda {
        font-size: 1.2rem !important;
    }

    .home-top-title-container {
        margin-top: 2.5rem !important;
        padding-top: 1.5rem !important;
        padding-bottom: 2rem !important;
        padding-left: $layout-x-padding-lg !important;
        padding-right: 5rem !important;
        margin-right: $layout-x-padding-lg !important;
        border-radius: 0 10rem 10rem 0;
    }
}

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .home-top-title-container {
        margin-top: 2.5rem !important;
        padding-top: 1.5rem !important;
        padding-bottom: 2rem !important;
        padding-left: $layout-x-padding-xl !important;
        padding-right: 5rem !important;
        margin-right: $layout-x-padding-xl !important;
        border-radius: 0 10rem 10rem 0;
    }
}





/* Small devices (tablets, 767px and own) */
@media (max-width: 767px) {
    .home-content-col {
        padding-left: $layout-x-padding-sm;
        padding-right: $layout-x-padding-sm;
        padding-bottom: $navbar-height !important;
    }

    .padding-layout-home {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: $navbar-height !important;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .home-content-col {
        padding-left: $layout-x-padding-md !important;
        padding-right: $layout-x-padding-md !important;
        padding-bottom: $navbar-height !important;
    }

    .padding-layout-home {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: $navbar-height !important;
    }
}

/* Large devices (desktops, 992px and up)  */
@media (min-width: 992px) {
    .home-content-col {
        padding-left: $layout-x-padding-lg !important;
        padding-right: 0 !important;
        padding-bottom: $navbar-height !important;
    }

    .padding-layout-home {
        padding-left: 0 !important;
        padding-right: $layout-x-padding-lg !important;
        padding-top: 2.25rem !important;
        padding-bottom: 0 !important;
    }
}

.mt-navbar {
    margin-top: $navbar-height;
}

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .home-content-col {
        padding-left: $layout-x-padding-xl !important;
        padding-right: 0 !important;
        padding-bottom: $navbar-height !important;
    }

    .padding-layout-home {
        padding-left: 0 !important;
        padding-right: $layout-x-padding-xl !important;
        padding-top: 2.25rem !important;
        padding-bottom: 0 !important;
    }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .home-content-col {
        padding-left: $layout-x-padding-xxl !important;
        padding-right: 0 !important;
        padding-bottom: $navbar-height !important;
    }

    .padding-layout-home {
        padding-left: 0 !important;
        padding-right: $layout-x-padding-xxl !important;
        padding-top: 2.25rem !important;
        padding-bottom: 0 !important;
    }
}

.dialog-public-profile {
    height: 100%;
    display: flex;
}