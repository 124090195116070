@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';

.recoverPassword{
    background-color: $login-bg-color;
    height: 100vh;
    width: 100vw;
}


.recoverPassword-container{
    background-color: $card-bg-color;
    height: 100vh;
    padding-top: $navbar-height;
}

.recoverPassword-content{
    width: 100%;
}




.recoverPassword-sidebar-background-container{
    background-color: $body-bg-color;
    position: relative;
}

.recoverPassword-sidebar-background-container::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* background-size: 100vw 100vw; */
    background-size: 130vh 130vh;
    transform: rotate(0deg);
    /* animation: anim_fondo2 120s linear 0s infinite; */
    background-image: url("../../../../public/media/fondos/fondo1.png");
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-color: $primary-color;
    mix-blend-mode: luminosity;
}

@keyframes anim_fondo2 {
    0%{
        background-position: -20vw 0vw;  
        background-size: 100vw 100vw;      
    }
    25%{
        background-position: -60vw -40vw;    
        background-size: 140vw 140vw;    
    }
    50%{
        background-position: -20vw -40vw;     
        background-size: 100vw 100vw;   
    }
    75%{
        background-position: 0 -40vw;    
        background-size: 140vw 140vw;    
    }
    100%{
        background-position: -20vw 0vw;   
        background-size: 100vw 100vw;     
    }
}