

@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';

/* .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw !important;
    z-index: 112 !important;
    height: $navbar-height;
    padding-top: 0;
    padding-bottom: 0;
    background-color: $navbar-bg-color !important;
    color: $navbar-text-color;

    -webkit-box-shadow: 10px 10px 17px -10px rgba(0, 0, 0, 0.72);
    box-shadow: 10px 10px 17px -10px rgba(0, 0, 0, 0.72);
}
 */
.navbar {
    padding: 0;
    font-size: 1rem !important;
    z-index: 112 !important;
    width: 100%;
    height: $navbar-height;
    background-color: $navbar-bg-color !important;
    color: $navbar-text-color;

    -webkit-box-shadow: 10px 10px 17px -10px rgba(0, 0, 0, 0.72);
    box-shadow: 10px 10px 17px -10px rgba(0, 0, 0, 0.72);
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 991px) {
    /* .navbar {
        position: absolute !important;
    } */
}

.logo-principal {
    height: calc($navbar-height) - 2*$general-spacing !important;

}

.toggle-sidebar-button {}

.toggle-sidebar-button:hover {
    background-color: $navbar-bg-color-hover;
}

.logo-principal-container {
    /* margin-right: 2rem; */
}