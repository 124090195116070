@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';

.label-primary {
    color: $primary-color;
}

.label {
    font-size: 0.95rem;
    font-size: 1.05rem;
    font-weight: 700;
    margin-bottom: 0.2rem;
}

.text-input {
    width: 100% !important;
}

.text-input-error {
    border: 1px solid red;
}

.checkbox-input {
    margin-right: 0.8rem;
}

input, textarea {
    border: 0.1rem solid rgb(190, 190, 190);
    border-radius: 0.6rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

select {
    border: 0.1rem solid rgb(190, 190, 190);
    border-radius: 1rem;
    border-radius: 0.6rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}


/* CUSTOM SELECT */

.custom-select {
    margin: 0;
    padding: 0;
}

.required-label {
    color: $danger-color;
    margin-left: 0.3rem;
}