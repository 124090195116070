@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';



.rol-activo {
    background-color: $primary-color !important;
}

.separador-state-form{
    width: 3rem;
    height: 0px;
    border-radius: 90rem;
    border: 2px solid $text-color-2;
    margin: 0px $general-spacing-x05 0px $general-spacing-x05;
}

.definicion-password{
    font-size: 0.8rem;
    padding-bottom: 0rem;
}

.state-form{
    font-size: 1.2rem;
    width: 2.5rem;
    height: 2.5rem;
    font-weight: 800;
    border: 0px solid $text-color-2;
    background-color: $navbar-bg-color;
    border-radius: 90rem;
    color: white;
}
.state-form.active{
    background-color: $primary-color;
    color: white;
    border: 0px solid $text-color-2;
}

.num-signup{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 1.7rem;
    width: 1.7rem;
    font-weight: 600;
    border-radius: 90rem;
    color: white;
    color: $primary-color;
    border: 0.1rem solid $primary-color;

    background-color: $primary-color;
    background-color: white;
}
