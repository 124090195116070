
@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';


.MuiDataGrid-main-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

/* .MuiDataGrid-main .MuiDataGrid-row,
.MuiDataGrid-main .MuiDataGrid-cell,
.MuiDataGrid-main .MuiDataGrid-columnHeaderTitle{
  max-height: 100vh !important;
  height: 100% !important;
}
 */

.MuiDataGrid-main .MuiDataGrid-columnHeaderTitle{
    border: 0px solid red;
    padding: 0px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-columnHeaderTitle div{
    width: 100%;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
}
.MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-columnHeaderTitle:not(:last-child) div{
    border-right: 1px solid white;
}

.MuiDataGrid-main .MuiDataGrid-columnHeaderTitle:first-of-type {
    border-top-left-radius: 10px;
}

.MuiDataGrid-main .MuiDataGrid-columnHeaderTitle:last-of-type {
    border-top-right-radius: 10px;
}

.MuiDataGrid-main .MuiDataGrid-row:last-of-type .MuiDataGrid-cell:first-of-type {
    border-bottom-left-radius: 10px;
}

.MuiDataGrid-main .MuiDataGrid-row:last-of-type .MuiDataGrid-cell:last-of-type {
    border-bottom-right-radius: 10px;
}

.MuiDataGrid-main .MuiDataGrid-columnHeaderTitle {
    white-space: nowrap;
    background-color: $secondary-color;
    color: white;

}

.MuiDataGrid-columnHeaders{
  background-color: $secondary-color;

}

/* .MuiDataGrid-main .MuiDataGrid-cell {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 2px solid #69696948;
    background-color: rgba(0,0,0,0.05);
} */

.MuiDataGrid-row{
  background-color: rgba(0,0,0,0.05);
  height: 100% !important;

}
/* .MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-cell {
    font-size: 1rem;
    height: 100%;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    display: flex;
    flex-direction: column;
    align-items: start !important;
    justify-content: center !important;
}
.MuiDataGrid-cell.MuiDataGrid-cell--textRight {
    display: flex;
    flex-direction: column;
    align-items: end !important;
    justify-content: center !important;
} */


/* .MuiDataGrid-main>tbody>.MuiDataGrid-row:nth-child(odd)>.MuiDataGrid-cell, 
.MuiDataGrid-main>tbody>.MuiDataGrid-row:nth-child(odd)>.MuiDataGrid-columnHeaderTitle {
   background-color: rgba(0,0,0,0.1); // Choose your own color here
 } */

 
 
 .MuiDataGrid-cell {
    font-size: 1rem;
    padding: 0.5rem 1rem 0.5rem 1rem !important;
}
 
 .MuiDataGrid-cell:nth-child(odd) {
    background-color: #69696923;
}



.MuiDataGrid-renderingZone {
    max-height: none !important;
}
.MuiDataGrid-cell {
    line-height: unset !important;
    max-height: none !important;
    white-space: normal;
}
.MuiDataGrid-row {
    max-height: none !important;
}

.MuiIconButton-root{
    color: white !important;
}