
$general-spacing: 1rem;
$general-spacing-x05: 0.5rem;
$general-spacing-x2: 2rem;
$general-spacing-x3: 3rem;
$general-spacing-x4: 4rem;
$general-spacing-x5: 5rem;
$general-spacing-x6: 6rem;
$layout-x-padding-xs: 0.8rem;
$layout-x-padding-sm: 1.3rem;
$layout-x-padding-md: 1.5rem;
$layout-x-padding-lg: 2.5rem;
$layout-x-padding-xl: 3.5rem;
$layout-x-padding-xxl: 4.5rem;


/* phones */
@media (max-width: 400px) {
  .padding-layout-x {
    padding-left: $layout-x-padding-xs;
    padding-right: $layout-x-padding-xs;
  }
  .padding-layout-left {
    padding-left: $layout-x-padding-xs;
  }
  .padding-layout-right {
    padding-right: $layout-x-padding-xs;
  }
}

/* Small devices (tablets, 767px and own) */
@media (min-width: 401px) {
    .padding-layout-x {
      padding-left: $layout-x-padding-sm;
      padding-right: $layout-x-padding-sm;
    }
    .padding-layout-left {
      padding-left: $layout-x-padding-sm;
    }
    .padding-layout-right {
      padding-right: $layout-x-padding-sm;
    }
  }
  
  /* Medium devices (tablets, 768px and up)*/
  @media (min-width: 768px) {
    .padding-layout-x {
      padding-left: $layout-x-padding-md;
      padding-right: $layout-x-padding-md;
    }
    .padding-layout-left {
      padding-left: $layout-x-padding-md;
    }
    .padding-layout-right {
      padding-right: $layout-x-padding-md;
    }
  }
  
  /* Large devices (desktops, 992px and up)  */
  @media (min-width: 992px) {
    .padding-layout-x {
      padding-left: $layout-x-padding-lg;
      padding-right: $layout-x-padding-lg;
    }
    .padding-layout-left {
      padding-left: $layout-x-padding-lg;
    }
    .padding-layout-right {
      padding-right: $layout-x-padding-lg;
    }
  }
  
  /* X-Large devices (large desktops, 1200px and up)*/
  @media (min-width: 1200px) {
    .padding-layout-x {
      padding-left: $layout-x-padding-xl;
      padding-right: $layout-x-padding-xl;
    }
    .padding-layout-left {
      padding-left: $layout-x-padding-xl;
    }
    .padding-layout-right {
      padding-right: $layout-x-padding-xl;
    }
  }
  
  /* XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 1400px) {
    .padding-layout-x {
      padding-left: $layout-x-padding-xxl;
      padding-right: $layout-x-padding-xxl;
    }
    .padding-layout-left {
      padding-left: $layout-x-padding-xxl;
    }
    .padding-layout-right {
      padding-right: $layout-x-padding-xxl;
    }
  }
  
// src/styles/spacings.scss

// Definición de la unidad base de espaciado
$spacing-unit: 8px;

// Definición de múltiplos para conveniencia
$spacing-unit-sm: $spacing-unit;
$spacing-unit-md: $spacing-unit * 2;
$spacing-unit-lg: $spacing-unit * 4;
$spacing-unit-xl: $spacing-unit * 8;


// src/styles/spacings.scss

$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;
