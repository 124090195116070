
/* TAMAÑOS COMPONENTES */
$sidebar-width: 5rem;
$open-sidebar-width: 12rem;
$navbar-height: 4.5rem;
$footer-height: 4rem;
$footer-height-sm: 8rem;
$settings-sidebar-width: 15rem;

/* :export {
    sidebar-width: $sidebar-width;
    open-sidebar-width: $open-sidebar-width;
    navbar-height: $navbar-height;
    footer-height: $footer-height;
    footer-height-sm: $footer-height-sm;
    settings-sidebar-width: $settings-sidebar-width;
} */

// src/styles/sizes.scss

$font-size-base: 1rem;
$font-size-lg: 1.25rem;
$font-size-md: 0.8rem;
$font-size-sm: 0.6rem;