@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';


.loading-backdrop {
    height: 100% !important;
    width: 100% !important;
    min-height: 70vh !important;
    /* min-width: 100vw !important; */
    background-image: url("../../../public/media/fondos/fondo1.png");
    background-color: $primary-color !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.loading-backdrop.fullscreen {
    min-height: calc(100vh - $navbar-height) !important;
}

.user-profile-container.interno {
    padding: 0 !important;
    margin: 0 !important;
}

.user-profile-container-external {
    padding: 0 !important;
    margin: 0 !important;
}

.user-profile-experiencias-laborales {
    background-color: $secondary-color-light;
    min-height: 20vh;
    padding-top: 2.25rem;
    padding-bottom: 0;
    color: white;
}

.user-profile-startups {
    background-color: $secondary-color-light;
    min-height: 20vh;
    padding-top: 2.25rem;
    padding-bottom: 0;
    color: white;
}

.user-profile-idiomas-tags {
    background-color: $primary-color;
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
}

.user-profile-idiomas-tags-item-container {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid white;
    padding: 1rem;
    border-radius: 1rem;
}

.user-profile-startups-experiences-container {
    background-color: $secondary-color-light;
    padding-bottom: 6rem;
}

.user-profile-idiomas-tags-item {
    background-color: white;
    color: $secondary-color;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.85rem;
    border-radius: 2rem;
}

.user-profile-top-card {
    z-index: 111 !important;
    padding-bottom: 4.5rem;
    min-height: 40vh;
    color: black;
    position: relative;
    /* background-image: url("../../../public/media/fondos/fondo-perfil-grande.svg");
    background-size: cover; */

}

.top-card-modal-buttons {
    padding-top: 2.5rem;
    padding-bottom: 4.5rem;
    z-index: 111 !important;
}

.user-profile-top-card:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.45;

    background-size: 130vh 130vh;
    background-image: url("../../../public/media/fondos/fondo-perfil-grande.svg");
    background-position: 0% 0%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1 !important;
}

/* X-Large devices (large desktops, 1200px and up)*/
@media (max-width: 1200px) {
    /* .user-profile-top-card:before {
        background-image: url("../../../public/media/fondos/fondo-perfil-chico.svg") !important;
    } */
}

.user-profile-top-card-desc {
    font-size: 1.1rem;
}

/* .userprofile-user-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
} */

/*PARA FORMULARIOSOLICITARTIEMPO */
.error-text {
    color: red;
    font-size: 16px;
    margin-top: 5px;
}

.userprofile-user-image {
    width: 14rem !important;
    height: 14rem !important;
    border-radius: 20rem;
}

.fs-titulo-profile {
    font-size: 1.8rem;
    font-weight: 500;
}

.btn-solicitar-reunion {
    font-size: 1.2rem;
    border-radius: 1.5rem !important;
    font-weight: 400 !important;
}

.userprofile-redes-sociales {
    line-height: 2.5rem; // Ajusta este valor según sea necesario
}

.userprofile-boton-redes-sociales {
    color: $primary-color;
    font-size: 2rem;
    margin-right: 0.5rem;
    
}

.userprofile-icono-boton-redes-sociales {
    height: 2.5rem;
    width: 2.5rem;
    fill: $primary-color !important;
    

}

.icon-container {
    position: relative;
    display: inline-block;
    margin-right: 5px; // Ajusta este valor según la distancia que desees
    vertical-align: middle; // Asegura que los contenedores estén alineados verticalmente
}

.asterisk {
    position: absolute;
    top: -1rem;
    right: 0;
    color: red;
    font-size: 1.5rem;
}

.primary-company-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border: 1px solid $primary-color;
    background-color: white;
    border-top-left-radius: 100rem;
    border-bottom-left-radius: 100rem;
    margin-left: 1rem !important;
    height: auto !important;
    padding: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 4rem;
    /* min-height: 20rem !important; */
    height: max-content !important;
    /* max-height: 20rem !important; */
    -webkit-box-shadow: 14px 10px 20px -5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 14px 10px 20px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 14px 10px 20px -5px rgba(0, 0, 0, 0.1);
}


.primary-company-img {
    display: block;
    max-width:12rem;
    max-height:8rem;
    width: auto;
    height: auto;
    border: 1px solid $primary-color;
    -webkit-box-shadow: 14px 10px 20px -5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 14px 10px 20px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 14px 10px 20px -5px rgba(0, 0, 0, 0.1);
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    padding: 0.3rem;
}

.primary-company-text {
    font-size: 0.8rem;
    text-align: center;
}

.tag-rol {
    background-color: $secondary-color-light;
    color: white;
    //padding: 0.4rem 1rem 0.4rem 1rem;
    padding: 0.3rem 0.8rem 0.3rem 0.8rem;
    border-radius: 0.5rem;
}