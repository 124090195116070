
@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';

.public-profile-userimage-container{
    /* display: flex;
    justify-content: center; */

}
.public-profile-userimage{
    width: 8rem;
    height: 8rem;
    border-radius: 90rem;
    background-color: lightgray;

}



