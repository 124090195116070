@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';

.btn-refresh {
  color: #000000 !important;
  font-size: 1rem;
  border-radius: 5rem;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: rgba(103, 103, 103, 0.331);
    color: rgba(103, 103, 103, 0.331) !important;
  }
}

.meetings-menu-itemtab {
  padding: 1.5rem !important;
}

.meetings-menu-item {
  border: 1px solid rgb(207, 207, 207);
  text-align: center;
  padding: 1.5rem !important;
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  transition: background-color 0.3s ease, color 0.3s ease;

  &.active {
    color: $primary-color;
    text-decoration: underline;
    background-color: rgba(25, 118, 210, 0.1);
  }

  &:hover {
    background-color: rgba(207, 207, 207, 0.3);
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.table th {
  border: 0px solid red;
  padding: 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  white-space: nowrap;
  background-color: $secondary-color;
  color: white;
}

.table tr th div {
  width: 100%;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.table tr th:not(:last-child) div {
  border-right: 1px solid white;
}

.table th:first-of-type {
  border-top-left-radius: 10px;
}

.table th:last-of-type {
  border-top-right-radius: 10px;
}

.table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
}

.table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}

.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 2px solid #69696948;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 1rem;
}

td:nth-child(odd) {
  background-color: #69696923;
}

.meetings-container {
  margin-top: 1rem;
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.MuiBox-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.events-users-container {
  padding-top: 0px !important;
  background-color: #f9f9f9;

  .menu-button {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    background-color: #1976d2;
    color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #1565c0;
    }
  }

  .drawer-box {
    background-color: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;

    .MuiTypography-h6 {
      margin-bottom: 20px;
      font-weight: bold;
      color: #1976d2;
    }

    .MuiListItem-root {
      &:hover {
        background-color: #e3f2fd;
      }
    }
  }

  .mobile-menu {
    .menu-row {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .menu-item {
      padding: 12px 20px;
      background-color: #ffffff;
      border: 2px solid #1976d2;
      border-radius: 8px;
      color: #1976d2;
      text-align: center;
      cursor: pointer;
      font-size: 1.1rem;
      transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;

      &.active {
        background-color: #1976d2;
        color: #ffffff;
        border-color: #1565c0;
      }

      &:hover {
        background-color: #e3f2fd;
        color: #1976d2;
        border-color: #1565c0;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .tabs-container {
    margin-bottom: 30px;

    .events-tabs {
      background-color: #ffffff;
      border-radius: 5px;
    }
  }

  .tabpanels-container {

    .inscription-day-section,
    .activities-inscription-section,
    .upcoming-activities-section,
    .activities-history-section {
      background-color: #ffffff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s ease, box-shadow 0.3s ease;
    }

    .header-section {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      gap: 10px;

      .section-title {
        font-size: 1.5rem;
        margin: 0;
      }

      .refresh-button {
        color: #000000 !important;
        padding: 8px;
        border-radius: 50%;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          background-color: rgba(103, 103, 103, 0.331) !important;
        }
      }
    }

    .instruction-text {
      margin-bottom: 20px;
      color: #555555;
      font-size: 1rem;
      line-height: 1.5;
    }

    .tickets-info {
      margin-bottom: 20px;

      strong {
        color: #1976d2;
      }
    }

    .tickets-used-info {
      margin-bottom: 20px;
      color: #333333;
    }

    .button-message {
      margin-bottom: 20px;
      color: #1976d2;
      font-weight: bold;
      font-size: 1rem;
    }

    .events-checkboxes {
      margin-bottom: 20px;

      .MuiFormControlLabel-root {
        margin-bottom: 10px;
        font-size: 1rem;
        position: relative;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;


        .MuiCheckbox-root {
          color: #b0bec5;

          &.Mui-checked {
            color: $primary-color;
            transition: background-color 0.3s ease, border-color 0.3s ease;
          }

          &.Mui-disabled {
            color: #e0e0e0;
          }

          svg {
            transition: transform 0.3s ease;
          }
        }
      }

      .MuiFormControlLabel-root {
        transition: background-color 0.3s ease, color 0.3s ease;
      }
    }

    .buttons-section {
      display: flex;
      gap: 15px;
      margin-bottom: 20px;
      flex-wrap: wrap;

      .confirm-button {
        background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
        border: none;
        color: white;
        padding: 12px 24px;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        box-shadow: 0 3px 5px rgba(33, 150, 243, 0.3);
        transition: background 0.3s ease, transform 0.2s ease;

        &:hover {
          background: linear-gradient(45deg, #1e88e5 30%, #1ecbf3 90%);
          transform: translateY(-2px);
        }

        &:active {
          transform: translateY(0);
          box-shadow: 0 2px 4px rgba(33, 150, 243, 0.2);
        }

        &:disabled {
          background: #90caf9;
          cursor: not-allowed;
          box-shadow: none;
        }
      }

      .modify-button {
        background-color: #e53935;
        border: none;
        color: #ffffff;
        padding: 12px 24px;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        box-shadow: 0 3px 5px rgba(229, 57, 53, 0.3);
        transition: background 0.3s ease, transform 0.2s ease;

        &:hover {
          background-color: #c62828;
          transform: translateY(-2px);
        }

        &:active {
          transform: translateY(0);
          box-shadow: 0 2px 4px rgba(229, 57, 53, 0.2);
        }

        &:disabled {
          background-color: #ef9a9a;
          cursor: not-allowed;
          box-shadow: none;
        }
      }

      .buy-ticket-button {
        border: 2px solid #1976d2;
        border-radius: 25px;
        color: #1976d2;
        padding: 12px 24px;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          background-color: #e3f2fd;
          color: #1565c0;
        }

        &:active {
          background-color: #bbdefb;
        }

        &:disabled {
          border-color: #90caf9;
          color: #90caf9;
          cursor: not-allowed;
        }
      }
    }

    .tickets-indicator {
      padding: 15px 20px;
      border-radius: 8px;
      margin-bottom: 20px;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      transition: background-color 0.3s ease, color 0.3s ease;
    }

    .tickets-indicator.sufficient {
      background-color: #d4edda;
      color: #155724;
    }

    .tickets-indicator.few {
      background-color: #fff3cd;
      color: #856404;
    }

    .tickets-indicator.none {
      background-color: #f8d7da;
      color: #721c24;
    }

    .activities-section {
      margin-top: 20px;

      .activities-title {
        margin-bottom: 15px;
        color: #1976d2;
        font-weight: bold;
      }

      .activity-accordion {
        margin-bottom: 10px;

        .MuiAccordionSummary-root {
          background-color: #f1f1f1;
          border-radius: 5px;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #e3f2fd;
          }

          .MuiTypography-root {
            font-weight: 500;
          }
        }

        .MuiAccordionDetails-root {
          background-color: #fafafa;
          border: 1px solid #e0e0e0;
          border-top: none;
          border-radius: 0 0 5px 5px;
          transition: background-color 0.3s ease, border 0.3s ease;
        }
      }
    }

    .loading,
    .error {
      text-align: center;
      font-size: 1.2rem;
      color: #555555;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    .loading {
      color: #1976d2;
    }

    .error {
      color: #e53935;
    }

    .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }

    .validation-container {
      position: relative;
      margin-top: 0.5rem;
      padding: 10px 15px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      &.warning {
        background-color: #FFF9C4;
        border: 1px solid #FFEB3B;
        color: #F57F17;
        opacity: 1;
        animation: fadeIn 0.2s ease-in-out forwards;
      }

      &.error {
        background-color: #FFCDD2;
        border: 1px solid #F44336;
        color: #B71C1C;
        opacity: 1;
        animation: fadeIn 0.2s ease-in-out forwards;
      }

      &.success {
        background-color: #C8E6C9;
        border: 1px solid #4CAF50;
        color: #1B5E20;
        opacity: 1;
        animation: fadeIn 0.2s ease-in-out forwards;
      }

      @keyframes fadeIn {
        from {
          opacity: 0;
          transform: translateY(-10px);
        }

        to {
          opacity: 1;
          transform: translateY(0);
        }
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
          transform: translateY(0);
        }

        to {
          opacity: 0;
          transform: translateY(-10px);
        }
      }

      &.fade-out {
        animation: fadeOut 0.2s ease forwards;
      }

      .warning-icon {
        margin-right: 8px;
        font-size: 20px;
        color: #F57F17;
      }
    }

    @media (max-width: 992px) {
      .events-users-container {
        padding: 0px !important;

        .drawer-box {
          padding: 10px;
        }

        .tabs-container {
          display: none;
        }

        .mobile-menu {
          .menu-row {
            .menu-item {
              font-size: 1rem;
              padding: 8px 12px;
            }
          }
        }
      }
    }
  }
}

.desktop-tabs {
  .MuiTabs-flexContainer {
    gap: 20px;
  }

  .MuiTab-root {
    font-size: 1rem;
    font-weight: 500;
    padding: 12px 16px;
    transition: color 0.3s ease, border-bottom 0.3s ease;

    &.Mui-selected {
      color: $primary-color;
      border-bottom: 3px solid $primary-color;
    }

    &:hover {
      color: darken($primary-color, 10%);
    }
  }

  .MuiTabs-indicator {
    height: 3px;
    background-color: $primary-color;
  }
}

.validation-message {
  margin-top: 8px;
  text-align: center;
  font-weight: bold;
  color: #d32f2f;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.activity-accordion {
  margin-bottom: 1rem;
}

.events-checkboxes {
  margin-top: 1rem;
}

.buttons-section {
  margin-top: 1rem;
}

.validation-message {
  margin-top: 0.5rem;
}