@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';


.CardExperiencia{
    color: $secondary-color;    
    border: 1px solid $secondary-color-light;
    position: relative;
    padding: 1.4rem;
}
.CardExperiencia-number{
    position: absolute !important;
    left: -1.25rem !important;
    top: 1rem !important;
    background-color: white !important;
    border: 1px solid $secondary-color-light !important;
    color: $secondary-color-light !important;
    font-weight: 700 !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 3rem !important;
}

.CardExperiencia-cargo{
    display:block;
    font-size: 1.4rem;
}
.CardExperiencia-compania {
    display:block;
    font-size:0.8rem;
    color:#009fe3;
}
.CardExperiencia-industria {
    display:block;
    font-size:0.8rem;
}

.CardExperiencia-date {
    font-size:0.8rem;
    display:block;
}

.CardExperiencia-fotoCompany-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 100%;
}
.CardExperiencia-fotoCompany{
    max-width: 10rem;
    max-height: 5rem;
}