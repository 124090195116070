@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';

@mixin card-background($image-url){
    background-image: url($image-url);
}
.card-container{
    position: relative;
    min-height: 100vh;
}
.row{
    margin:0;
    padding: 0;
}
.card-contenedor{
    display: flex;
    justify-content: center;
    height: 100vh; 
    padding: 20px;
}
.card-landing-new{
    position: relative;
    background-size: cover;
    background-position: center;
    width: 350px; // Ancho de la tarjeta
    height: 450px; // Altura de la tarjeta
    display: flex;
    flex-direction: column;
    justify-content: flex-end; // Alinea el contenido al final de la tarjeta (parte inferior)
    align-items: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.705); // Sombra suave para dar profundidad
    border-radius: 8px; // Bordes redondeados
    color: #fff; // Color del texto
    text-align: center; // Centra el texto horizontalmente

    @include card-background('');

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #03456180; // Capa semi-transparente de tono negro
        border-radius: 8px; // Bordes redondeados para coincidir con la tarjeta
      }
    .card-content {
        //margin-top: 65%;
        font-family: 'Space Grotesk', sans-serif;
        z-index: 2; // Asegura que el contenido esté por encima del fondo
        
        .card-title{
            font-size: 2em;
            
        }
        .card-text{
            font-size: 0.9em;
            margin-top: 2em;
            margin-bottom: 1em;
        }
        .card-button {
            background-color:white;
            border: 1px solid #009EE3;
            font-size: 1rem;
            padding: 0.5em 1em;
            margin-top: 2rem; // Espacio entre el texto y el botón
            border-radius: 8px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            border-width: 0.05cm;
            &:hover {
                background-color: #009EE3;
                color:white;
                text-decoration: none;
            }
        }
    }   
    
}

/* Pantallas pequeñas (teléfonos móviles) */
@media (max-width: 599px) {
    .card-landing-new {
        padding: 0.5rem;
        .card-button{
            font-size: 0.9rem;
        }
    }
}

/* Pantallas medianas (tabletas en modo retrato) */
@media (min-width: 600px) and (max-width: 899px) {
    .card-landing-new {
        width: 80%;
        padding: 0.75rem;
    }
}

/* Pantallas grandes (tabletas en modo paisaje y laptops pequeñas) */
@media (min-width: 900px) and (max-width: 1199px) {
    .card-landing-new {
        width: 80%;
        padding: 1rem;
    }
}

/* Pantallas extra grandes (laptops y desktops) */
@media (min-width: 1200px) {
    .card-landing-new {
        width: 100%;
        padding: 1rem;
    }
}

.card-landing {
    position: relative;
    width: 300px; // Ancho de la tarjeta
    height: 450px; // Altura de la tarjeta
    background-size: cover;
    background-position: center;
    border-radius: 8px; // Opcional: bordes redondeados
    overflow: hidden; // Para mantener todo dentro de los bordes redondeados
    align-items: center;
    
    @include card-background('');

    .card-content {
      font-family: 'Space Grotesk', sans-serif;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      background: #47758586; // Fondo semitransparente para el texto
      color: white; // Color del texto
      padding: 10px; // Espacio alrededor del texto
      padding-top: 80%;
      
      .card-title{
        font-size: 1.5em;
      }
      .card-text{
        font-size: 0.8em;
        margin-bottom: 1em;
      }
      .card-button {
        width: 220px;
        font-family: 'Space Grotesk', sans-serif;
        background-color: white;
        color: #009EE3;
        font-weight: 600;
        font-size: small;
        border-radius: 2rem;
        text-align: center;
        display: inline-block;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border: 1px solid #009EE3;
        cursor: pointer;
        margin: 0 auto;
        transition: background-color 0.3s ease;
        border-width: 0.05cm;
        
        &:hover {
          background-color: #009EE3;
          color:white;
        }
      }
    }
  }
  
.card-experto{
    @include card-background('experto.jpg')
}
.card-emprendedor{
    @include card-background('emprendedor.jpg')
}
.card-emprendedor2{
    @include card-background('../../../public/media/new landing/emprendedor2.jpg')
}
.landing-container {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
}

.landing-page {
    margin-top: 3rem;
    position: relative;
    height: auto !important;
    width: 100%;
    /* overflow: hidden; */
}

.fondo-landing-1-bg-img {
    background-color: #E2E2E2;
    mix-blend-mode: darken;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    opacity: 0.6;
    margin-top: 20rem;
}

.fondo-landing-2-bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    opacity: 0.6;
    background-color: black;
}

/* INICIO LANDING */
.fondo-landing-titulo-1 {
    /*border-left: 0.3rem solid $primary-color;*/
    font-family: 'Space Grotesk', sans-serif;
    color: $primary-color;
    padding-left: 1rem;
    font-weight: 400;
}
.fondo-landing-titulo-2 {
    /*border-left: 0.3rem solid $primary-color;*/
    font-family: 'Space Grotesk', sans-serif;
    padding-left: 1rem;
    font-weight: 400;
    font-size: 800;
}
.fondo-landing-titulo-3 {
    /*border-left: 0.3rem solid $primary-color;*/
    font-family: 'Space Grotesk', sans-serif;
    padding-left: 2rem;
    padding-top: 10rem;
    font-weight: 400;
    font-size: 800;
}
.fondo-landing-titulo-4 {
    /*border-left: 0.3rem solid $primary-color;*/
    font-family: 'Space Grotesk', sans-serif;
    padding-left: 4rem;
    padding-top: 3rem;
    font-weight: 400;
    font-size: 800;
}

.fondo-landing-subtitulo-1 {
    font-family: 'Space Grotesk', sans-serif;
    border-left: 0.3rem solid rgba(0, 0, 0, 0);
    padding-left: 1rem;
}
.fondo-landing-subtitulo-2 {
    font-family: 'Space Grotesk', sans-serif;
    border-left: 0.3rem solid rgba(0, 0, 0, 0);
    padding-left: 1rem;
    padding-bottom: 1rem;
}
.fondo-landing-subtitulo-3 {
    font-family: 'Space Grotesk', sans-serif;
    border-left: 0.3rem solid rgba(0, 0, 0, 0);
    padding-left: 4rem;
    padding-bottom: 1rem;
}

.landing-inicio-container-foto-1 {
    /* height: 20rem; */
    width: 100%;
}
.landing-inicio-foto1 {
    width: 120%;
    height: 100%;
    object-fit: cover;
    @media (max-width: 768px){
        max-width: 100%;
        height: auto;
    }
    /* opacity: 0.9; */
}
.landing-inicio-foto2 {
    height: 90%;
    width: 90%;
    margin-left: 5rem;
    object-fit: cover;
    @media (max-width: 768px){
        max-width: 100%;
        height: auto;
    }
    /* opacity: 0.9; */
}
.landing-inicio-foto3 {
    height: 100%;
    width: 80%;
    margin-left: 5rem;
    object-fit: cover;
    @media (max-width: 768px){
        max-width: 100%;
        height: auto;
    }
    /* opacity: 0.9; */
}
.landing-inicio-foto-1 {
    height: 85%;
    width: 90%;
    height: calc(100vh - $navbar-height);
    padding-left: 5rem;
    object-fit: cover;
    /* opacity: 0.9; */
}
.btn-agenda{
    font-family: 'Space Grotesk', sans-serif;
    background-color: white;
    color: #009EE3;
    font-weight: 600;
    font-size: 800;
    border-radius: 2rem;
    display: inline-block;
    text-align: center;
    margin-left: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid #009EE3;
    box-shadow: 0px 2px 4px #81CEE8;
    border-width: 0.08cm;
    &:hover {
        background-color: #009EE3;
        color:white;
        text-decoration: none;
      }
}
.btn-eventos{
    font-family: 'Space Grotesk', sans-serif;
    text-align: center;
    background-color: white;
    color: #009EE3;
    font-weight: 600;
    font-size: 800;
    border-radius: 2rem;
    margin-left: 4rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid #009EE3;
    box-shadow: 0px 2px 4px #81CEE8;
    border-width: 0.08cm;
    text-align: center;
    display: inline-block;
    &:hover {
        background-color: #009EE3;
        color:white;
        text-decoration: none;
      }
}
.landing-inicio-card-que-es-container {
    background-color: $primary-color;
    padding: 0;
    border-top-right-radius: 15rem;
    border-bottom-right-radius: 15rem;
    min-height: 60vh;
    color: white;
}
.landing-inicio-listado{
    padding-left: 1rem;
}
.landing-inicio-lista{
    font-family: 'Space Grotesk', sans-serif;
    padding-left: 1rem;
    padding-top: 1rem;
}

.landing-inicio-card-que-es {
    /* padding-right: 8rem !important; */
    padding-right: 2rem;
    padding-right: 4rem;
}

.landing-inicio-card-que-es-line {
    border-bottom: 1.5px solid white;
}

.landing-inicio-conceptos-container {}

.landing-inicio-concepto {
    width: 8rem;
}


.landing-inicio-concepto-foto {
    width: 5rem;
    height: 5rem;
    transition: 0.5s;
}
/* 
.landing-inicio-concepto:hover .landing-inicio-concepto-foto {
    width: 5.5rem;
    height: 5.5rem;
    color: $primary-color;
} */

.landing-inicio-concepto-texto {}

/* hasta Large devices (desktops, 992px and down)  */
@media (max-width: 992px) {
    .landing-inicio-conceptos-container {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .landing-inicio-concepto-foto {
        width: 4rem;
        height: 4rem;
    }

    .landing-inicio-concepto-texto {
        font-size: 1rem;
    }

    .landing-inicio-concepto {
        width: 6rem;
    }

    .fondo-landing-intro {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .fondo-landing-titulo-1 {
        font-size: 1.8rem;
    }

    .landing-inicio-foto-1 {
        height: 100%;
        min-height: 40vh;
        width: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        /* opacity: 0.9; */

    }

    .landing-inicio-card-que-es-container {
        background-color: $primary-color;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        min-height: 50vh;
        color: white;
    }

    .landing-inicio-card-que-es {
        padding: 2rem !important;
    }
}






/* ROLES PERFILES */
.landing-page-roles {
    background-color: $navbar-bg-color;
}

.landing-page-roles-titulo {
    color: white;
}

.landing-page-roles-line {
    border-bottom: 1.5px solid white;

}

.landing-page-roles-rol {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}

.landing-page-roles-foto-rol {
    background-color: rgb(177, 176, 176);
    border-radius: 8rem;
    height: 8rem;
    width: 8rem;
}

.landing-page-roles-rol-nombre {
    color: white;
}

.landing-page-roles-rol-desc {
    color: white;
}

/* FORMULA  */
.landing-page-formula-stat {
    /* min-width: 15rem; */
    margin: 0 !important;
}

.landing-page-formula-stat-value {
    font-size: 3.5rem;
}

.landing-page-formula-stat-texto {
    width: 12rem;
    font-size: 1.1rem;
}

/* EXPERIENCIA */
.titulo-experiencias {
    /* width: 26rem; */
    color: $navbar-bg-color;
    text-align: center;
}

.card-experiencia {
    /* height: 60vh !important; */
    position: relative;
    background-color: $primary-color;
    padding: 5rem;
    border-radius: 1rem;
    color: white;
    /* margin-left: 5rem;
    margin-right: 5rem; */
    background-image: url("../../../public/media/landing/fondo_punto.svg");
    background-size: 3rem 3rem;
    background-repeat: round;
    text-align: start !important;

}
.card-experiencia-expertos{
    background-color: $secondary-color !important;
}



.card-experiencia-foto {
    background-color: $primary-color !important;
    height: 14rem !important;
    width: 14rem !important;
    border-radius: 12rem !important;
    border: 0px solid white !important;
}

.card-experiencia-foto-comillas {
    position: absolute;
    height: 5rem !important;
    width: 5rem !important;
    top: 3rem !important;
    left: 3rem !important;

}


.swiper-container {
    position: relative;
}

.swiper-button {
    display: flex;
    position: absolute;
    top: calc(50% - 12px);
    z-index: 10;
    cursor: pointer;
}

.swiper-button svg {
    width: 1.5rem;
    height: 1.5rem;
}

.image-swiper-button-prev {
    left: 5px;
}

.image-swiper-button-next {
    right: 5px;
}

.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
}


/* hasta Large devices (desktops, 992px and down)  */
@media (max-width: 992px) {

    .card-experiencia {
        padding: 3rem !important;
        /* height: 90vh !important; */

    }

    .card-experiencia-foto {
        height: 10rem !important;
        width: 10rem !important;
        margin-bottom: 2rem !important;
    }

    .card-experiencia-foto-comillas {
        top: 13rem !important;
        left: 1.5rem !important;
    }
}

.vive_la_experiencia_foto_pc, .vive_la_experiencia_foto_tablet, .vive_la_experiencia_foto_celu {
    width: 100%;
    height: auto;
    height: min-content;
}
/* 
vive_la_experiencia_foto_pc
vive_la_experiencia_foto_tablet
*/

/* hasta Large devices (desktops, 992px and down)  */
@media (max-width: 650px) {
    
    .vive_la_experiencia_foto_pc{
        width: 319px !important;
        height: 193px !important;
    } 
    .vive_la_experiencia_foto_tablet{
        width: 177px !important;
        height: 260px !important;
    } 
    .vive_la_experiencia_foto_celu{
        width: 147px !important;
        height: 282.52px !important;
    } 

}
/* QUIENES SOMOS */
.landing-page-quienes-somos {
    background-color: $primary-color;
    position: relative;
    margin-bottom: 8rem !important;
}

.etm-day-circle {
    cursor: pointer;
    text-decoration: none;
    color: white;
    background-color: #8CB923;
    height: 13rem;
    width: 13rem;
    padding: 1rem;
    border-radius: 10rem;
    position: absolute;
    bottom: -10rem;
    right: 5rem;
}

.etm-day-circle:hover {
    background-color: #9ecc33;
}
.etm-day-circle-foto-click{
    height: 2rem;
    margin-bottom: 0.5rem;
    margin-right: 2rem;
}
.etm-day-circle-foto-etmday{
    margin-top: 0.5rem;
    height: 3.5rem;

}

.landing-page-quienes-somos-titulo {
    color: white !important
}

.landing-quienes-somos-container-foto {
    min-height: 35vh;
    height: 100%;
    width: 100%;
}

.landing-quienes-somos-foto {
    height: 100% !important;
    width: 100% !important;
    border-top-right-radius: 100rem !important;
    border-bottom-right-radius: 100rem !important;
    background-image: url("../../../public/media/landing/equipo/equipo.JPG") !important;
    background-size: cover !important;
    background-repeat: none !important;
}
@media (min-width: 1000px) {
    .landing-quienes-somos-foto {
        min-height: 30rem !important;
    }
}


.landing-quienes-somos-container-foto-sm {
    height: 100%;
    width: 100%;
}

.landing-quienes-somos-foto-sm {

    width: 100vw;
    object-fit: cover;
    /* background-image: url("../../../public/media/landing/equipo/equipo.JPG");
    background-size: 100% auto; */
}

.landing-page-quienes-somos-linea {
    border-bottom: 2px solid white;
    width: 100%;
}

/* hasta Large devices (desktops, 992px and down)  */
@media (max-width: 992px) {
    .etm-day-circle {
        right: 2rem;
    }
}



/* EQUIPO */
.landing-page-equipo {}

.landing-page-equipo-card-persona {
    padding-bottom: 1rem;
}

.landing-page-equipo-card-persona-foto {
    width: 12rem;
    background-color: gray;
    border-radius: 100rem;
}

.landing-page-equipo-card-persona-cargo {
    font-size: 1.1rem
}

.landing-page-equipo-card-persona-logo {
    height: 1.8rem;
}

/* SPONSORS */

.landing-page-sponsors-partners {
    background-color: $bg-color-3;
    background-image: url("../../../public/media/landing/fondos/fondo-negro.svg");
    background-size: 100% auto;
}

.landing-page-sponsors-partners-titulo {
    color: white !important;
}

.foto-sponsor {
    width: 200px;
    height: auto;
}

.foto-partners {
    width: 20%;
    height: auto;
}

/* hasta Large devices (desktops, 992px and down)  */
@media (max-width: 992px) {
    .foto-sponsor {
        width: 5rem;
        height: auto;
    }

    .foto-partners {
        width: 5rem;
        height: auto;
    }

}



.swiper-container-sponsors .swiper-slide {
    width: 30% !important;
    /* height: 8rem !important; */
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.swiper-container-sponsors img {
    width: 90% !important;
    height: auto !important;
    max-height: 6rem !important;
}

.swiper-container-partners .swiper-slide {
    width: 10% !important;
    /* height: 100% !important; */
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.swiper-container-partners img {
    width: 90% !important;
    height: auto !important;
    max-height: 5rem !important;
}




/* FOOTER */
.landing-page-footer {
    background-color: #1C1C1C;
    color: white;
}

.landing-page-footer-foto {
    height: 3.5rem;
}
.powered-by-ey{
    height: 2.5rem;
    width: 8rem;
}

/* SWIPER */
.swiper-slide{
    /* height: 100% !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important; */
}
.foto-sponsors{
   /*  height: 15rem !important;
    min-width: 10rem !important;
    max-width: 15rem !important; */
}