.btn-refresh{
    font-size: 1rem;
    border-radius: 5rem;
    width: 1.8rem;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.btn-refresh:hover{
    background-color: rgba(103, 103, 103, 0.331);
}
.table-container-loading{
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
}

.table-container-loading{
    width: 100%;
    overflow: hidden;
}

.contenedor-tipo-solicitud{
    word-wrap: break-word;      /* IE 5.5-7 */
    white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
    white-space: pre-wrap;      /* current browsers */
}
.tipo-solicitud{
}