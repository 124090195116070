label {
    margin:0px;
}
.degree-box{
    border:1px solid #ccc;
}
.degree-name{
    display:block;
    font-size:1rem;
}

.degree-university {
    display:block;
    font-size:0.8rem;
    color:#009fe3;
}
.degree-country {
    display:block;
    font-size:0.8rem;
}

.degree-date {
    font-size:0.8rem;
    display:block;
}