/* Archivo: src/styles/common-select.scss */

.common-form-control {
    min-width: 200px; // Aumentar el ancho mínimo para vista de escritorio
    max-width: 300px; // Definir un ancho máximo para que no ocupe todo el espacio disponible
    width: 100%; // Ajustar automáticamente al contenedor
    margin-right: 1rem; // Mantener el margen derecho para vistas de escritorio
  
    .MuiOutlinedInput-root {
      font-size: 0.95rem; // Aumentar ligeramente el tamaño de fuente para mejor visibilidad
      height: 44px; // Incrementar altura para mejor accesibilidad
  
      .MuiSelect-select {
        padding: 12px; // Ajustar padding para mayor comodidad
      }
    }
  }
  
  // Media Queries para vista móvil
  @media (max-width: 600px) {
    .common-form-control {
      min-width: 100%; // Ocupa el ancho completo del contenedor en dispositivos móviles
      max-width: 100%;
      margin-right: 0; // Eliminar margen derecho
      margin-bottom: 1rem; // Agregar margen inferior para separar elementos
    }
  }
  