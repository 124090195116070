@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';

$card-background-color: #ffffff;
$card-hover-background-color: #f0f2f5;
$card-border-color: #e0e0e0;
$card-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
$card-hover-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
$inscribirse-button-color: #009EE3;
$inscribirse-button-hover-color: darken($inscribirse-button-color, 10%);
$expertos-button-color: #6c757d;
$expertos-button-hover-color: darken($expertos-button-color, 10%);
$primary-accent-color: #1976d2;
$secondary-accent-color: #dc004e;
$success-color: #4caf50;
$danger-color: #f44336;
$button-transition-duration: 0.3s;

*, *::before, *::after {
    box-sizing: border-box;
}

.flip-card {
    background-color: transparent;
    width: 100%;
    max-width: 400px;
    perspective: 1000px;
    position: relative;
    height: 350px;
    overflow: hidden;
    border-radius: 12px;
    margin: 0 auto;

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    &.flipped .flip-card-inner {
        transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        top: 0;
        left: 0;
        border-radius: 12px;
        box-sizing: border-box;
        padding: $spacing-unit;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        background-color: $card-background-color;
        transition: background-color $button-transition-duration, box-shadow $button-transition-duration;

        &.flip-card-back {
            background-color: $card-hover-background-color;
            border: 1px solid $card-border-color;
            box-shadow: $card-shadow;
            transform: rotateY(180deg);
            z-index: 1;
        }
    }

    .flip-card-front {
        z-index: 2;
        transform: rotateY(0deg);
    }

    .flip-card-back {
        z-index: 1;
        transform: rotateY(180deg);
    }

    .activity-card__type,
    .flip-card-back .expertos-title {
        font-size: 1.2rem;
        font-weight: bold;
        color: $primary-accent-color;
        margin-bottom: $spacing-unit / 2;
        text-transform: uppercase;
        letter-spacing: 1px;
        word-wrap: break-word;
    }

    .flip-card-front .activity-card__type,
    .flip-card-back .expertos-title {
        align-self: flex-start;
        text-align: left;
    }

    .activity-card__middle-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        text-align: center;
        padding: 0 10px;
    }

    .activity-card__name {
        font-size: 1.1rem;
        margin-bottom: $spacing-unit / 2;
        color: $secondary-color;
        font-weight: 600;
        text-align: center;
        white-space: normal;
        overflow: hidden;
        word-wrap: break-word;
        padding-top: 20px;

        @media (min-width: 768px) {
            font-size: 1.2rem;
        }

        @media (min-width: 1024px) {
            font-size: 1.3rem;
        }
    }

    .activity-card__schedule {
        margin-top: $spacing-unit / 2;
        font-size: 1rem;
        color: #555;
        text-align: center;
    }

    .flip-card-back .expertos-list {
        max-height: 150px;
        overflow-y: auto;
        list-style-type: disc;
        padding-left: 1.5rem;
        width: 100%;
        text-align: left;
    }

    .flip-card-back .expertos-list li {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        color: #333;
    }

    .activity-card {
        &__container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            transition: box-shadow $button-transition-duration, background-color $button-transition-duration, transform $button-transition-duration;
            background-color: $card-background-color;
            border: 1px solid $card-border-color;
            border-radius: 12px;
            box-shadow: $card-shadow;
            padding: $spacing-unit;
            margin: 0;
            overflow: hidden;

            &:hover {
                transform: scale(1.02);
                box-shadow: $card-hover-shadow;
                background-color: $card-hover-background-color;
            }
        }

        &__type {
            font-weight: bold;
            color: $primary-accent-color;
            font-size: 1.2rem;
            margin-bottom: $spacing-unit / 2;
            text-transform: uppercase;
            letter-spacing: 1px;
            word-wrap: break-word;
            text-align: left;
            align-self: flex-start;
        }

        &__middle-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            text-align: center;
            padding: 0 10px;
        }

        &__actions {
            padding: 0;
            display: flex;
            justify-content: space-between;

            @media (max-width: 600px) {
                flex-direction: column;
                gap: $spacing-unit / 2;
            }
        }

        &__button {
            flex: 1;
            margin: 0 4px;

            &.inscribirse-button {
                background-color: $inscribirse-button-color;
                color: #fff;
                border: none;
                cursor: pointer;
                transition: background-color $button-transition-duration, transform $button-transition-duration;

                &:hover {
                    background-color: $inscribirse-button-hover-color;
                    transform: translateY(-2px);
                }

                &:active {
                    background-color: darken($inscribirse-button-color, 15%);
                    transform: translateY(0);
                }

                @media (max-width: 600px) {
                    width: 100%;
                    margin: 0;
                }
            }

            &.volver-button {
                background-color: $secondary-accent-color;
                color: #fff;
                border: none;
                cursor: pointer;
                transition: background-color $button-transition-duration, transform $button-transition-duration;

                &:hover {
                    background-color: darken($secondary-accent-color, 10%);
                }

                &:active {
                    background-color: darken($secondary-accent-color, 15%);
                }

                @media (max-width: 600px) {
                    width: 100%;
                    margin: 0;
                }
            }

            &.expertos-button {
                background-color: $expertos-button-color;
                color: #fff;
                border: none;
                cursor: pointer;
                transition: background-color $button-transition-duration, transform $button-transition-duration;

                &:hover {
                    background-color: $expertos-button-hover-color;
                    transform: translateY(-2px);
                }

                &:active {
                    background-color: darken($expertos-button-color, 15%);
                    transform: translateY(0);
                }

                @media (max-width: 600px) {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .snackbar {
        &-success {
            background-color: $success-color;
        }

        &-error {
            background-color: $danger-color;
        }
    }

    .expertos-box {
        background-color: #f0f0f0;
        padding: $spacing-unit;
        border-radius: 8px;
    }

    .flip-card-back .expertos-list {
        max-height: 150px;
        overflow-y: auto;
        list-style-type: disc;
        padding-left: 1.5rem;
        width: 100%;
        text-align: left;
    }

    .flip-card-back .expertos-list li {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        color: #333;
    }
}
