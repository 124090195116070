.qr-reader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9;
    padding: 16px;
    text-align: center;
}

.qr-scanner-wrapper {
    width: 100%;
    max-width: 300px;
    height: 300px;
    border: 2px dashed #aaa;
    border-radius: 8px;
    margin-bottom: 16px;
}

.qr-result {
    font-size: 16px;
    color: #333;
    margin-top: 16px;
}

.qr-result p {
    margin: 4px 0;
    color: #666;
}

.qr-link-button,
.qr-reset-button {
    margin-top: 8px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Estilo para el botón de abrir enlace */
.qr-link-button {
    background-color: #009EE3;
    color: #fff;
}

.qr-link-button:hover {
    background-color: #007db8;
}

/* Estilo para el botón de reiniciar */
.qr-reset-button {
    background-color: #FF9800;
    color: #fff;
    margin-top: 12px;
}

.qr-reset-button:hover {
    background-color: #e68900;
}

.qr-warning {
    font-size: 14px;
    color: #ff5733;
    margin-top: 12px;
    text-align: center;
    max-width: 300px;
}

.qr-warning p {
    margin: 0;
    line-height: 1.4;
}