@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';


.card-usuario {
    position: relative !important;
    padding: 0rem !important;
    cursor: pointer !important;
    margin-bottom: 2.5rem !important;
    padding: 1.5rem !important;
    width: 100% !important;
    
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    

    border-radius: 2rem !important;
    border: 1px solid rgba(170, 170, 170, 0.8) !important;
}

.card-usuario:hover{
    transition: 0.5s;
    border: 1px solid $primary-color !important;
    -webkit-box-shadow: 1.2rem 1.2rem 0.7rem -0.7rem rgba(100,100,100,0.3);
    -moz-box-shadow: 1.2rem 1.2rem 0.7rem -0.7rem rgba(100,100,100,0.3);
    box-shadow: 1.2rem 1.2rem 0.7rem -0.7rem rgba(100,100,100,0.3);
}


.card-usuario-jobtitle{
    font-size: 0.9rem;
}

.h-card-user{
}

.card-usuario .nombre-usuario {
    font-size: 1.3rem;
    line-height: 1.3rem;
    margin-bottom: 0;
}

.card-usuario .org-usuario {
    font-size: 0.8rem;
}

.card-usuario .rol-usuario {}

.card-usuario-desc {
    font-size: 0.9rem;
}
.avatar-usuario-container{
    position: relative !important;
}
.avatar-usuario {
    height: 11rem !important;
    width: 11rem !important;
    font-size: 6rem;
}
.avatar-usuario-match-percentage{
    position: absolute;
    background-color: $primary-color;
    background-color: $secondary-color-light;

    bottom: 1rem !important;
    right: 0.5rem !important;
    border-radius: 10rem !important;
    width: 1.8rem !important;
    height: 1.8rem !important;
    font-weight: 700;
    font-size: 0.6rem !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}



.img-company-usuario-container {
    max-height: 6rem;
    max-width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-company-usuario {
    max-height: 4rem !important;
    min-width: 4rem !important;
    max-width: 8rem !important;
    width: auto !important;
    object-fit: contain !important;
}

.fav-usuario-container {
    position: absolute;
    right: 1rem;
}

/* 
 */

.badge-rol {
    font-size: 0.55rem;
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;
    color: white;
    border-radius: 2rem;
}

.badge-rol-emprendedor {
    background-color: rgb(207, 151, 67);
}

.badge-rol-experto {
    background-color: rgb(0, 89, 255);
}

.user-save-button {
    cursor: pointer;
}

.user-saved {
    color: #81CEE8;
    color: #ffc400ba;
}

.btn-solicitar-reunion-card-home-exp {
    font-size: 0.9rem;
    border-radius: 8rem !important;
    font-weight: 500 !important;
    margin-top: 0.5rem;
    background-color: #5cb85c; // Tono de verde agradable
    color: white; // Color del texto a blanco

    // Para el efecto hover, oscureceremos un poco el tono para proporcionar un contraste visual:
    &:hover, &:focus, &:active {
        background-color: #4ca550; // Tono de verde un poco más oscuro para el hover
        color: white;
    }
}
.btn-solicitar-reunion-card-home-exp-2 {
    font-size: 0.9rem;
    border-radius: 8rem !important;
    font-weight: 500 !important;
    margin-top: 0.5rem;
    background-color: #a5291e; // Tono de rojo menos brillante
    color: white; // Color del texto a blanco

    // Para el efecto hover, puedes oscurecer un poco más el tono para proporcionar un contraste visual:
    &:hover, &:focus, &:active {
        background-color: #c44237; // Tono de rojo más oscuro para el hover
        color: white;
    }
}
.btn-ver-perfil-card-exp {
    font-size: 0.9rem;
    border-radius: 8rem !important;
    font-weight: 500 !important;
    margin-top: 0.1rem; 
  }


.name-company-usuario{
    font-size: 0.7rem !important;
    width: 90%;
}
.name-company-sponsor-usuario{
    color: $primary-color;
    font-size: 0.7rem !important;
}


.h-card-user{
    height: auto !important;
}




/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

    .card-usuario {
        position: relative;
        padding: 0rem !important;
        border-radius: 10rem 2rem 2rem 10rem !important;
    }
    .h-card-user{
        height: 11rem !important;
    }
    .card-usuario:hover{
        /* margin-top: 0.55rem;
        margin-bottom: 0.55rem; */
        transition: 0.5s;
    }

    .card-usuario:hover .avatar-usuario {
        /* border-radius: 0; */
        /* height: 7.70rem !important;
        width: 7.70rem !important;
        font-size: 3rem;
        transition: 0.5s; */
    }

}