@import '../../styles/colors.scss';

.tarjeta-container{
  margin: 0.5rem;
}
.tarjeta-experto {
    border: 1px solid #81CEE8; 
    border-radius: 8px; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
    background-color: #F7FCFE; 
    text-align: center; 
    font-family: 'Space Grotesk', sans-serif;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    height: 460px;
    justify-content: center;
    
    .tarjeta-content {
      padding: 10px; 
      display:flex;
      flex-direction: column;

      .tarjeta-imagen {
        width: 100%; 
        height: 100%; 
        cursor: pointer;
        display:flex;
        align-items: flex-end;
        justify-content: center;
        border-radius: 18px;
        margin-bottom: 0.3rem;
        position: relative !important;
        margin-left: 0.4rem;

        .img-container{
          width: 150px;
          height: 150px;
          border-radius: 18px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          .imagen-user{
            max-width: 100%;
            max-height: 100%;
            border-radius: 18px;
            object-fit: contain;
          }
        }
        
        .porcentaje{
          position:initial;
          background-color: $secondary-color-light;
          bottom: 1rem !important;
          right: 0.5rem !important;
          border-radius: 10rem !important;
          width: 1.8rem !important;
          height: 1.8rem !important;
          font-weight: 700;
          font-size: 0.6rem !important;
          color: white !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
        }
    }
        .etiqueta-container{
            background-color:#81CEE8 ;
            border-radius: 10px;
            .etiqueta-texto{
                color:white;
                font-size: 0.9em;
                padding: 2px;
            }
        }
      .tarjeta-title {
        margin: 10px 0; 
        font-size: 1.2em; 
        cursor: pointer;
      }
      .tarjeta-descripcion {
        font-size: 0.8em; 
        color: #666;
        
      }
      .tarjeta-ver-mas {
        font-size: 1em; 
        color: #009EE3;
        cursor: pointer;
        
      }
      .sin-descripcion{
        font-size: 0.8em; 
        color: #666; 
      }
      .ver-opiniones{
        cursor: pointer;
        color:#6A6A6A;
        font-weight: bold;
      }
      
    }
    .tarjeta-btn{
      padding: 5px;
      padding-bottom: 10px;

      .btn-agenda{
          padding: 2px 8px;
          width: auto;
          border-radius: 15px;
          background-color: #ffffff;
          color: #009EE3;
          border: 2px solid #009EE3;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  
          @media (max-width: 768px) {
              width: 80%;
          }
      }
    }
    
  }
  