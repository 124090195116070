@import '../../../styles/colors.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/spacings.scss';
@import '../../../styles/sizes.scss';

.card-rol.rol-activo {
    /* border: 2px dashed $primary-color; */
    background-color: white !important;
    border: 1px solid $primary-color;
}

.card-rol {
    font-size: 0.95rem;
    padding: 2rem !important;
    /* border: 2px dashed $secondary-color; */
}

.card-rol-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 10rem;
}

.img-rol {
    height: 6rem;
    width: 6rem;
    margin-bottom: 1rem;
}

/* Small devices (tablets, 767px and own) */

@media (max-width: 767px) {
    .card-rol-desc {
        min-height: 0rem;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .card-rol-desc {
        min-height: 13rem;
    }
}

/* Large devices (desktops, 992px and up)  */
@media (min-width: 992px) {
    .card-rol-desc {
        min-height: 0rem;
    }
}

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .card-rol-desc {
        min-height: 10rem;
    }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .card-rol-desc {
        min-height: 0rem;
    }
}
@mixin card-fondo($image-url){
    background-image: url($image-url);
}
.card-contenedor{
    display: flex;
    justify-content: center;
    padding: 20px;
}
.switch{
    color:#000fe3
    
}
.card-new{
    position: relative;
    background-size: cover;
    background-position: center;
    width: 25rem; // Ancho de la tarjeta
    height: 30rem; // Altura de la tarjeta
    display: flex;
    flex-direction: column;
    justify-content: flex-end; // Alinea el contenido al final de la tarjeta (parte inferior)
    align-items: center;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.705); // Sombra suave para dar profundidad
    border-radius: 8px; // Bordes redondeados
    color: #fff; // Color del texto
    text-align: center; // Centra el texto horizontalmente

    @include card-fondo('../../Landing/emprendedor.jpg');

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #02405a80; // Capa semi-transparente de tono negro
        border-radius: 8px; // Bordes redondeados para coincidir con la tarjeta
      }
    .card-content {
        //margin-top: 65%;
        font-family: 'Space Grotesk', sans-serif;
        z-index: 2; // Asegura que el contenido esté por encima del fondo
        
        .card-title{
            font-size: 2em;
            
        }
        .card-text{
            font-size: 0.9em;
            margin-top: 2em;
            margin-bottom: 1em;
        }
    }   
    
}

.card-experto{
    @include card-fondo('../../Landing/experto.jpg')
}
.card-emprendedor{
    @include card-fondo('../../Landing/emprendedor.jpg')
}
/* Small devices (tablets, 767px and own) */


/* Pantallas pequeñas (teléfonos móviles) */
@media (max-width: 599px) {
    .card-new {
        padding: 0.5rem;
    }
}

/* Pantallas medianas (tabletas en modo retrato) */
@media (min-width: 600px) and (max-width: 899px) {
    .card-new {
        width: 80%;
        padding: 0.75rem;
    }
}

/* Pantallas grandes (tabletas en modo paisaje y laptops pequeñas) */
@media (min-width: 900px) and (max-width: 1199px) {
    .card-new {
        width: 80%;
        padding: 1rem;
    }
}

/* Pantallas extra grandes (laptops y desktops) */
@media (min-width: 1200px) {
    .card-new {
        width: 100%;
        padding: 1rem;
    }
}