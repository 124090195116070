.room{
  background-color: rgb(26, 26, 26);
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
}

.room-footer{
  height: 4rem;
  display: flex;
  align-items: center;
  background-color: rgb(97, 97, 97);
}
.btn-room-margin{
  margin-right: 0.5rem;
}

.participants-container{
  background-color: black;
  position: relative;
}
.participants {
  
  /* min-height: min(calc(11.34rem*5), 100vh) !important;;
  width: 100%;
  background-color: rgb(29, 29, 29);
  padding: 1rem; */
}
.participant-container{
  padding: 0.5rem;
}

.app {
  padding: 0.75vw;
  width: 100%;
  box-sizing: border-box;
}

.controls {
  margin-top: 25px;
  margin-bottom: 50px;
}

.controls input {
  font-size: 1.25em;
}




.breakout {
  background-color: #feae01;
  display: inline-block;
}

video {
  display: block;
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}



