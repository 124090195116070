@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/spacings.scss';
@import '../../styles/sizes.scss';

.table-container {
    width: 100%;
    overflow-x: auto;
}

.MuiDataGrid-root .MuiDataGrid-cell {
    white-space: normal;
    word-wrap: break-word;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    text-align: center;
}

.circular-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.circular-progress {
    position: relative;
    margin-bottom: 20px;
}

.circular-progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.btn-cancel {
    background-color: #dc3545;
    color: #fff;
    text-transform: none;
    padding: 5px 10px;
    font-size: 0.875rem;
    min-height: 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
}

.btn-access {
    background-color: #007bff;
    color: #fff;
    text-transform: none;
    padding: 5px 10px;
    font-size: 0.875rem;
    min-height: 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
}

.meetings-menu-itemtab {
    padding: 1.5rem !important;
}

.meetings-menu-item {
    border: 1px solid rgb(207, 207, 207);
    text-align: center;
    padding: 1.5rem !important;
    cursor: pointer;
    margin-bottom: 1rem;
    font-size: 1.3rem;
}

.meetings-menu-item.active {
    color: $primary-color;
    text-decoration: underline;
}

.table th {
    border: 0px solid red;
    padding: 0px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.table tr th div {
    width: 100%;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.table tr th:not(:last-child) div {
    border-right: 1px solid white;
}

.table th:first-of-type {
    border-top-left-radius: 10px;
}

.table th:last-of-type {
    border-top-right-radius: 10px;
}

.table tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 10px;
}

.table tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 10px;
}

.table th {
    white-space: nowrap;
    background-color: $secondary-color;
    color: white;
}

.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 2px solid #69696948;
    background-color: rgba(0, 0, 0, 0.05);
}

.table th,
.table td {
    font-size: 1rem;
}

td:nth-child(odd) {
    background-color: #69696923;
}

.meetings-container {
    margin-top: 1rem;
    padding-top: 2rem;
    padding-bottom: 5rem;
}

.MuiBox-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.label {
    font-size: 1.05rem;
    font-weight: 700;
    margin-bottom: 0.2rem;
}

.label-primary {
    color: #009EE3;
}

.m-0 {
    margin: 0;
}

.p-0 {
    padding: 0;
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-danger:hover {
    background-color: #dc3545;
    color: #fff;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.mr-2 {
    margin-right: 0.5rem;
}

@media (max-width: 600px) {
    .MuiDataGrid-root .MuiDataGrid-cell {
        padding: 4px;
    }

    .MuiDataGrid-colCellWrapper .MuiDataGrid-colCell[aria-colindex="3"],
    .MuiDataGrid-colCellWrapper .MuiDataGrid-colCell[aria-colindex="4"],
    .MuiDataGrid-colCellWrapper .MuiDataGrid-colCell[aria-colindex="5"],
    .MuiDataGrid-colCellWrapper .MuiDataGrid-colCell[aria-colindex="6"] {
        display: none;
    }
}

@media (max-width: 768px) {
    .MuiDataGrid-root {

        .MuiDataGrid-cell,
        .MuiDataGrid-columnHeader {
            white-space: normal;
        }
    }
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}